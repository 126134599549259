import { useSearchParams } from "react-router-dom";

const useSearchParamsState = (
    searchParamName: string,
    defaultValue: string
): readonly [
    searchParamsState: string,
    setSearchParamsState: (newState: string) => void
] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const acquiredSearchParam = searchParams.get(searchParamName);
    const searchParamsState = acquiredSearchParam ?? defaultValue;

    const setSearchParamsState = (newState: string) => {
        const next = Object.assign(
            {},
            [...searchParams.entries()].reduce(
                (o, [key, value]) => ({ ...o, [key]: value }),
                {}
            ),
            { [searchParamName]: newState }
        );
        setSearchParams(next);
    };
    return [searchParamsState, setSearchParamsState];
}

const useSearchParamsStateFlag = (
    searchParamName: string,
    defaultValue: boolean
): readonly [
    searchParamsState: boolean,
    setSearchParamsState: (newState: boolean) => void
] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const acquiredSearchParam = searchParams.has(searchParamName);
    const searchParamsState = acquiredSearchParam ?? defaultValue;

    const setSearchParamsState = (newState: boolean) => {
        const next = Object.assign(
            {},
            [...searchParams.entries()]
                .filter(([k, v]) => k !== searchParamName)
                .reduce(
                    (o, [key, value]) => ({ ...o, [key]: value }),
                    {}
                )
        ) as any;

        if (newState) {
            next[searchParamName] = 'true';
        }

        setSearchParams(next);
    };
    return [searchParamsState, setSearchParamsState];
}

export { useSearchParamsState, useSearchParamsStateFlag }