import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/TagRule.png';

const WNTagRule = () => {
    return (
        <>
            <Typography variant='h5'>Tags</Typography>
            <Typography variant='body1'>You have the option to associate a tag with any number of rules, and rules can have any number of tags. There are also a number of system tags which you may find useful.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Tag Rule" />
        </>
    )
}

export { WNTagRule }