import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormLabel, Grid, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import callDciApi, { callDciApiCancellable, CancellablePromise, postDciApiNoThrow } from '../../utils/callDciApi';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import OrgContainer from '../Organisation/OrgContainer';
import { useMasterRules } from '../Api/MasterRules';
import { useSnackbar } from 'notistack';

interface MasterRule {
    masterRuleId: number,
    description: string
}

type ReportTest = {
    reportTestId: number,
    versionNumber: number,
    enabled: boolean,
    ruleDescription: string,
    masterRule: MasterRule | null
}

type RuleMetadata = {
    reportTestId: number,
    versionNumber: number,
    enabledForOrganisation: boolean
}

type ReportTestGroup = {
    reportTestGroupId: number,
    description: string,
    enabled: boolean
}

type DisplayItem = {
    index: number,
    reportTest: ReportTest,
    ruleMetadata: RuleMetadata | null,
    reportTestGroupId: number | null
}

const reportTestQueryColumns = 'reportTestId,versionNumber,enabled,ruleDescription,masterRule{masterRuleId,description}';

const loadReportTests = (token: string, setDisplayItems: (displayItems: DisplayItem[]) => void, setReportTestsLoaded: React.Dispatch<React.SetStateAction<boolean>>) => {
    const cancellablePromise = callDciApiCancellable(`{allReportTests(order:{reportTestId:ASC,versionNumber:ASC}){nodes{${reportTestQueryColumns}}}}`, token);
    cancellablePromise.promise.then(body => {
        if (!body.errors) {
            setDisplayItems(body.data.allReportTests.nodes.map((m: any, i: number) => ({
                index: i,
                reportTest: m,
                ruleMetadata: null,
                reportTestGroupId: null
            }) as DisplayItem));
            setReportTestsLoaded(true);
        }
    });

    return cancellablePromise;
}

const loadRuleMetadata = (token: string, setDisplayItems: React.Dispatch<React.SetStateAction<DisplayItem[]>>) => {
    const cancellablePromise = callDciApiCancellable('{allRuleMetadata(order:{reportTestId:ASC,versionNumber:ASC}){nodes{reportTestId,versionNumber,enabledForOrganisation}}}', token);
    cancellablePromise.promise.then(body => {
        if (!body.errors) {
            setDisplayItems(di => di.map(m => ({
                    index: m.index,
                    reportTest: m.reportTest,
                    ruleMetadata: body.data.allRuleMetadata.nodes.find((x: any) => x.reportTestId === m.reportTest.reportTestId && x.versionNumber === m.reportTest.versionNumber) ?? null,
                    reportTestGroupId: m.reportTestGroupId
                }) as DisplayItem)
            );
        }
    });

    return cancellablePromise;
}

const loadReportingRunData = (
    token: string, 
    setDisplayItems: React.Dispatch<React.SetStateAction<DisplayItem[]>>,
    setReportTestGroups: React.Dispatch<React.SetStateAction<ReportTestGroup[]>>
) => {
    const cancellablePromise = callDciApiCancellable('{organisationReportTestGroups{nodes{reportTestGroupId,description,enabled,reportTestGroupReportTest{reportTestGroupId,reportTestId}}}}', token);
    cancellablePromise.promise.then(body => {
        if (!body.errors) {
            setDisplayItems(di => {
                const reportTestGroups = [] as ReportTestGroup[];
                const reportTestReportTestGroupMappings = [] as { reportTestId: number, reportTestGroupId: number }[];

                for (const rtg of body.data.organisationReportTestGroups.nodes) {
                    if (!reportTestGroups.find(x => x.reportTestGroupId === rtg.reportTestGroupId)) {
                        reportTestGroups.push({
                            description: rtg.description,
                            enabled: rtg.enabled,
                            reportTestGroupId: rtg.reportTestGroupId
                        })
                    }

                    for (const rt of rtg.reportTestGroupReportTest) {
                        const rtgrt = reportTestReportTestGroupMappings.find(x => x.reportTestId === rt.reportTestId);

                        // Note that we are only supporting a report test belonging to one report test group here, and later ones found will overwrite earlier ones
                        if (rtgrt) {
                            rtgrt.reportTestGroupId = rtg.reportTestGroupId;
                        } else {
                            reportTestReportTestGroupMappings.push({
                                reportTestGroupId: rtg.reportTestGroupId,
                                reportTestId: rt.reportTestId
                            })
                        }
                    }
                }

                setReportTestGroups(reportTestGroups);

                return di.map(m => ({
                    index: m.index,
                    reportTest: m.reportTest,
                    ruleMetadata: m.ruleMetadata,
                    reportTestGroupId: reportTestReportTestGroupMappings.find(x => x.reportTestId === m.reportTest.reportTestId)?.reportTestGroupId
                }) as DisplayItem);
            });
        }
    });

    return cancellablePromise;
}

const CoreRulesList = () => {
    const paperRef = useRef<HTMLDivElement>(null);
    const { enqueueSnackbar } = useSnackbar();
    
    const [ paperWidth, setPaperWidth ] = useState<number | null>();
    const [ paperHeight, setPaperHeight ] = useState<number | null>();
    
    const { getAccessTokenSilently } = useAuth0();
    const [ displayItems, setDisplayItems ] = useState<DisplayItem[]>([]);
    const [ reportTestsLoaded, setReportTestsLoaded ] = useState(false);
    const [ reportTestGroups, setReportTestGroups ] = useState<ReportTestGroup[]>([]);

    const [ reportTestsUpdating, setReportTestsUpdating ] = useState<number[]>([]);
    const [ ruleMetadataUpdating, setRuleMetadataUpdating ] = useState<number[]>([]);
    const [ reportTestGroupReportTestsUpdating, setReportTestGroupReportTestsUpdating ] = useState<number[]>([]);

    const { data: masterRules, isFetching } = useMasterRules();
    
    useEffect(() => {
        let cancellablePromise: CancellablePromise | null = null;
        const loadData = async () => {
            const token = await getAccessTokenSilently();
            cancellablePromise = loadReportTests(token, setDisplayItems, setReportTestsLoaded);
        }

        loadData();
        return () => cancellablePromise?.abortController.abort();
    }, [ getAccessTokenSilently ]);

    useEffect(() => {
        if (!reportTestsLoaded) {
            return;
        }

        let metadataCancellablePromise: CancellablePromise | null = null;
        let reportingRunCancellablePromise: CancellablePromise | null = null;
        const loadData = async () => {
            const token = await getAccessTokenSilently();
            metadataCancellablePromise = loadRuleMetadata(token, setDisplayItems);
            reportingRunCancellablePromise = loadReportingRunData(token, setDisplayItems, setReportTestGroups);
        }

        loadData();
        return () => {
            metadataCancellablePromise?.abortController.abort();
            reportingRunCancellablePromise?.abortController.abort();
        }
    }, [ reportTestsLoaded, getAccessTokenSilently ])

    const setReportTestEnabled = async (reportTestId: number, versionNumber: number, enabled: boolean) => {
        const token = await getAccessTokenSilently();
        const query = `mutation{setReportTestEnabled(reportTestId:${reportTestId},versionNumber:${versionNumber},enabled:${enabled}){${reportTestQueryColumns}}}`;
        setReportTestsUpdating(rts => [ ...rts, reportTestId ]);
        callDciApi(query, token).then(body => {
            setReportTestsUpdating(rts => rts.filter(x => x !== reportTestId));
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setDisplayItems(di => di.map(m => m.reportTest.reportTestId === reportTestId 
                    ? {
                        ...m,
                        reportTest: body.data.setReportTestEnabled,
                      } as DisplayItem
                    : m));
                enqueueSnackbar('Change saved.', { variant:'success' });
            }
        });
    }

    const setReportTestMasterRule = async (reportTestId: number, masterRuleId: number | null) => {
        const token = await getAccessTokenSilently();
        const query = `mutation{editReportTest(reportTestId:${reportTestId},masterRuleId:${masterRuleId}){${reportTestQueryColumns}}}`;
        setReportTestsUpdating(rts => [ ...rts, reportTestId ]);
        const mutationResponse = await postDciApiNoThrow(query, token);
        setReportTestsUpdating(rts => rts.filter(x => x !== reportTestId));
        if (mutationResponse.errors) {
            enqueueSnackbar(mutationResponse.errors[0].message, { variant:'error' });
        } else {
            // NOTE: We need to re-fetch the rule, as it is not possible for Hot Chocolate/EF to flesh out the master rule sub-property in the mutation
            const queryResponse = await postDciApiNoThrow(`{reportTestById(id:${reportTestId}){${reportTestQueryColumns}}}`, token);
            setDisplayItems(di => di.map(m => m.reportTest.reportTestId === reportTestId 
                ? {
                    ...m,
                    reportTest: queryResponse.data.reportTestById,
                    } as DisplayItem
                : m));
            enqueueSnackbar('Change saved.', { variant:'success' });
        }
    }

    const createRuleMetadataRecord = async (reportTestId: number, versionNumber: number) => {
        const token = await getAccessTokenSilently();
        const query = `mutation{createRuleMetadataRecord(reportTestId:${reportTestId},versionNumber:${versionNumber}){reportTestId,versionNumber,enabledForOrganisation}}`;
        setRuleMetadataUpdating(rts => [ ...rts, reportTestId ]);
        callDciApi(query, token).then(body => {
            setRuleMetadataUpdating(rts => rts.filter(x => x !== reportTestId));
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setDisplayItems(di => di.map(m => m.reportTest.reportTestId === reportTestId && m.reportTest.versionNumber === versionNumber 
                    ? {
                        ...m,
                        ruleMetadata: body.data.createRuleMetadataRecord,
                      } as DisplayItem
                    : m))
                enqueueSnackbar('Metadata record created.', { variant:'success' });
            }
        });
    }

    const setEnabledForOrganisation = async (reportTestId: number, versionNumber: number, enabled: boolean) => {
        const token = await getAccessTokenSilently();
        const query = `mutation{setReportTestEnabledForOrganisation(reportTestId:${reportTestId},versionNumber:${versionNumber},enabled:${enabled}){reportTestId,versionNumber,enabledForOrganisation}}`;
        setRuleMetadataUpdating(rts => [ ...rts, reportTestId ]);
        callDciApi(query, token).then(body => {
            setRuleMetadataUpdating(rts => rts.filter(x => x !== reportTestId));
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setDisplayItems(di => di.map(m => m.reportTest.reportTestId === reportTestId && m.reportTest.versionNumber === versionNumber 
                    ? {
                        ...m,
                        ruleMetadata: body.data.setReportTestEnabledForOrganisation,
                      } as DisplayItem
                    : m));
                enqueueSnackbar('Change saved.', { variant:'success' });
            }
        });
    }

    const createReportTestGroupReportTestRecord = async (reportTestId: number) => {
        if (reportTestGroups.length !== 1) {
            alert('Currently this UI only supports creating a record if there is only one Report Test Group for the organisation.');
            return;
        }

        const token = await getAccessTokenSilently();
        const query = `mutation{createReportTestGroupReportTestRecord(reportTestId:${reportTestId},reportTestGroupId:${reportTestGroups[0].reportTestGroupId}){reportTestGroupId,reportTestId}}`;
        setReportTestGroupReportTestsUpdating(rts => [ ...rts, reportTestId ]);
        callDciApi(query, token).then(body => {
            setReportTestGroupReportTestsUpdating(rts => rts.filter(x => x !== reportTestId));
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setDisplayItems(di => di.map(m => m.reportTest.reportTestId === reportTestId
                    ? {
                        ...m,
                        reportTestGroupId: body.data.createReportTestGroupReportTestRecord.reportTestGroupId
                      } as DisplayItem
                    : m));
                enqueueSnackbar('Report Test Group record created.', { variant:'success' });
            }
        });
    }

    const ReportTestView = ({ reportTest }: { reportTest: ReportTest }) => {
        return (
            reportTestsUpdating.findIndex(x => x === reportTest.reportTestId) === -1
            ? <>
                <ListItemText
                    primary={`Rule ${reportTest.reportTestId}`}
                    secondary={<Typography noWrap>{reportTest.ruleDescription}</Typography>}
                />
                
                <FormControlLabel
                    label='Globally Enabled'
                    control={
                        <Checkbox
                            color='secondary'
                            checked={reportTest.enabled}
                            onClick={() => setReportTestEnabled(reportTest.reportTestId, reportTest.versionNumber, !reportTest.enabled)}
                        />
                    }
                />
                <Autocomplete
                    options={masterRules ?? []}
                    loading={isFetching}
                    getOptionLabel={o => o.description}
                    isOptionEqualToValue={(o, v) => o.masterRuleId === v.masterRuleId}
                    onChange={(_event, value, _reason) => setReportTestMasterRule(reportTest.reportTestId, value === null ? null : value.masterRuleId)}
                    value={reportTest.masterRule}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='standard'
                            className='nodrag'
                            fullWidth
                            size='small'
                            label='Master Rule'
                            margin='dense'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                                ),
                            }}
                        />
                    )}
                />
            </>
            : <CircularProgress />
        )
    }

    const RuleMetadataView = ({ reportTestId, versionNumber, ruleMetadata }: { reportTestId: number, versionNumber: number, ruleMetadata: RuleMetadata | null }) => {
        return (
            ruleMetadataUpdating.findIndex(x => x === reportTestId) === -1
            ? ruleMetadata 
                ? <>
                    <Typography>Metadata record present</Typography>
                    <Checkbox color='secondary' style={{ padding:'0px' }} checked={ruleMetadata.enabledForOrganisation} onClick={() => setEnabledForOrganisation(reportTestId, versionNumber, !ruleMetadata.enabledForOrganisation)} />
                    <FormLabel>Enabled for organisation</FormLabel>
                </>
                : <Box>
                    <Button variant='contained' onClick={() => createRuleMetadataRecord(reportTestId, versionNumber)}>Create Metadata Record</Button>
                </Box>
            : <CircularProgress />
        )
    }

    const ReportingRunDataView = ({ reportTestId, reportTestGroupId }: { reportTestId: number, reportTestGroupId: number | null }) => {
        return (
            reportTestGroupReportTestsUpdating.findIndex(x => x === reportTestId) === -1
            ? reportTestGroupId
                ? <>
                    <Typography>Report Test Group: {reportTestGroups.find(x => x.reportTestGroupId === reportTestGroupId)?.description}</Typography>
                </>
                : <Box>
                    <Button variant='contained' onClick={() => createReportTestGroupReportTestRecord(reportTestId)}>Add to Reporting Run</Button>
                </Box>
            : <CircularProgress />
        )
    }

    const renderRow = ({ index, style }: ListChildComponentProps) => {
        return (
            <ListItem sx={theme => ({ borderBottom:`1px solid ${theme.palette.action.disabled}` })} style={style} key={`${displayItems[index].reportTest.reportTestId}-${displayItems[index].reportTest.versionNumber}`}>
                <Grid container direction='row' spacing={1}>
                    <Grid item lg={6} xs={12}>
                        <ReportTestView reportTest={displayItems[index].reportTest} />
                    </Grid>
                    <Grid item lg={3} xs={6}>
                        <RuleMetadataView reportTestId={displayItems[index].reportTest.reportTestId} versionNumber={displayItems[index].reportTest.versionNumber} ruleMetadata={displayItems[index].ruleMetadata} />
                    </Grid>
                    <Grid item lg={3} xs={6}>
                        <ReportingRunDataView reportTestId={displayItems[index].reportTest.reportTestId} reportTestGroupId={displayItems[index].reportTestGroupId} />
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    // TODO: This needs fixing - height is responsive but width is not - similar to graph issue?
    const onResize = () => {
        setPaperWidth(paperRef?.current?.offsetWidth);
        setPaperHeight(paperRef?.current?.offsetHeight);
    }

    useEffect(() => {
        setPaperWidth(paperRef?.current?.offsetWidth);
        setPaperHeight(paperRef?.current?.offsetHeight);
        
        window.addEventListener('resize', onResize)

        return () => window.removeEventListener('resize', onResize)
    }, [ paperRef?.current?.offsetWidth, paperRef?.current?.offsetHeight ])

    return (
        <OrgContainer>
            <Paper style={{ height:'calc(100vh - 140px)' }} ref={paperRef}>
                { paperWidth && paperHeight &&
                    <FixedSizeList
                        height={paperHeight}
                        width={paperWidth}
                        itemCount={displayItems.length}
                        itemSize={175}
                        overscanCount={5}
                    >
                        {renderRow}
                    </FixedSizeList>
                }
            </Paper>
        </OrgContainer>
    )
}

export { CoreRulesList }