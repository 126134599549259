import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/FilterByTag.png';

const WNFilterByTag = () => {
    return (
        <>
            <Typography variant='h5'>Tags</Typography>
            <Typography variant='body1'>Once tagged, you can use the new filter in the Work Item views to narrow your search.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Filter by Tag" />
            <Typography variant='body1'>There are new permissions for creating, editing and deleting tags, so be sure to apply these to any necessary roles.</Typography>
        </>
    )
}

export { WNFilterByTag }