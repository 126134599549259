import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
    Avatar,
    List, 
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Divider,
    ListSubheader,
    Box,
    styled
} from '@mui/material';
import {
    AccountTree,
    Assignment,
    Business,
    Engineering,
    Group,
    LocalOffer,
    Person,
    Rule,
    Schema,
    Settings,
    TableView,
    Work
} from '@mui/icons-material';
import dciPaths, { DciRoute } from '../../utils/dciPaths';
import { PermissionedComponent } from '../PermissionedComponent';
import { permissions } from '../../utils/dciConstants';
import { CompactMenu, CompactMenuHeader, CompactMenuItem } from '../Layout/CompactMenu';
import userHasPermission from '../../utils/userHasPermission';
import { appGlobalStore } from '../../AppGlobalStore';
import { useStore } from 'zustand';

const OrgMenu = styled(Paper)(({ theme }) =>`
    margin-right:20px;
    max-width:250px;
    min-width:250px;
    margin-bottom:${theme.spacing(2)};
    & .Mui-selected .MuiAvatar-root {
        background-color: ${theme.palette.secondary.main};
    }
`);

type OrgContainerProps = {
    children?: React.ReactNode
}

interface PermissionedMenuItemProps {
    caption: string
    route: DciRoute
    permissionId?: number | number[]
    icon: JSX.Element
}

const PermissionedMenuItem = ({ caption, route, permissionId, icon }: PermissionedMenuItemProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const pathName = useLocation().pathname;

    return <CompactMenuItem
        caption={caption}
        href={route.buildLink()}
        shouldDisplay={typeof permissionId === 'undefined'? true : Array.isArray(permissionId) ? (permissionId as number[]).some(p => userHasPermission(currentUser, p)) : userHasPermission(currentUser, permissionId)}
        selected={typeof route.regex === 'undefined' ? pathName.startsWith(route.buildLink()) : route.regex.test(pathName)}
        icon={icon}
    />
}

const OrgContainer = ({ children }: OrgContainerProps) => {
    const pathName = useLocation().pathname;
    const navigate = useNavigate();

    return (
        <Box height={'100%'} display='flex'>
            <div>
                <OrgMenu>
                    <CompactMenu>
                        <CompactMenuHeader>Configuration</CompactMenuHeader>
                        <PermissionedMenuItem caption='Organisation' icon={<Business />} route={dciPaths.organisation} />
                        <PermissionedMenuItem caption='Settings' icon={<Settings />} route={dciPaths.settings} permissionId={[permissions.VIEW_SYSTEM_SETTINGS, permissions.EDIT_SYSTEM_SETTINGS]} />
                        <PermissionedMenuItem caption='Constants' icon={<Settings />} route={dciPaths.orgConstants} permissionId={[permissions.VIEW_SYSTEM_SETTINGS, permissions.EDIT_SYSTEM_SETTINGS]} />
                        <PermissionedMenuItem caption='Value Lists' icon={<Settings />} route={dciPaths.orgValueLists} permissionId={[permissions.VIEW_SYSTEM_SETTINGS, permissions.EDIT_SYSTEM_SETTINGS]} />
                        <Divider />
                        <PermissionedMenuItem caption='Teams' icon={<Group />} route={dciPaths.teams} permissionId={permissions.VIEW_LIST_OF_TEAMS} />
                        <PermissionedMenuItem caption='Users' icon={<Person />} route={dciPaths.users} permissionId={permissions.VIEW_LIST_OF_USERS} />
                        <PermissionedMenuItem caption='Roles' icon={<Work />} route={dciPaths.roles} permissionId={permissions.VIEW_LIST_OF_ROLES} />
                        <PermissionedMenuItem caption='Work Queues' icon={<Assignment />} route={dciPaths.workQueues} />
                        <Divider />
                        <PermissionedMenuItem caption='Rule Groups' icon={<AccountTree />} route={dciPaths.ruleGroups} permissionId={permissions.VIEW_LIST_OF_RULES} />
                        <PermissionedMenuItem caption='Rules' icon={<AccountTree />} route={dciPaths.rules} permissionId={permissions.VIEW_LIST_OF_RULES} />
                        <PermissionedMenuItem caption='Tags' icon={<LocalOffer />} route={dciPaths.tags} permissionId={permissions.VIEW_LIST_OF_RULES} />
                    </CompactMenu>
                </OrgMenu>

                <PermissionedComponent permissionId={[ permissions.VIEW_EXTRACT_TABLES, permissions.VIEW_CORE_REPORT_TESTS, permissions.VIEW_ORGANISATION_SCHEMAS ]}>
                    <OrgMenu>
                        <CompactMenu>
                            <CompactMenuHeader>Core Admin</CompactMenuHeader>
                            <PermissionedMenuItem caption='Tables' icon={<TableView />} route={dciPaths.coreTables} permissionId={permissions.VIEW_EXTRACT_TABLES} />
                            <PermissionedMenuItem caption='Rules' icon={<Rule />} route={dciPaths.coreRulesList} permissionId={permissions.VIEW_CORE_REPORT_TESTS} />
                            <PermissionedMenuItem caption='Master Rules' icon={<Rule />} route={dciPaths.masterRules} permissionId={permissions.EDIT_CORE_RULES} />
                            <PermissionedMenuItem caption='Schema' icon={<Schema />} route={dciPaths.coreSchema} permissionId={permissions.VIEW_ORGANISATION_SCHEMAS} />
                            <PermissionedMenuItem caption='Core Roles' icon={<Engineering />} route={dciPaths.coreRoles} permissionId={permissions.VIEW_CORE_ROLES} />
                            <PermissionedMenuItem caption='Work Item Attribute Maps' icon={<AccountTree />} route={dciPaths.workItemAttributeMaps} permissionId={permissions.VIEW_WORK_ITEM_ATTRIBUTE_MAPS} />
                            <PermissionedMenuItem caption='Core Tags' icon={<LocalOffer />} route={dciPaths.coreTags} permissionId={permissions.EDIT_CORE_TAGS} />
                        </CompactMenu>
                    </OrgMenu>
                </PermissionedComponent>
            </div>
            <div style={{ flex:1 }}>
                {children}
            </div>
        </Box>
    )
};

export default OrgContainer;