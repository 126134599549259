import { DateFilter, DateFilterDefaultValue } from "./DateFilter";
import { PriorityFilter, PriorityFilterDefaultValue } from "./PriorityFilter";
import { RegulatoryImpactFilter, RegulatoryImpactFilterDefaultValue } from "./RegulatoryImpactFilter";
import { RuleFilter, RuleFilterDefaultValue } from "./RuleFilter";
import { TeamFilter, TeamFilterDefaultValue } from "./TeamFilter";
import { TextFilter, TextFilterDefaultValue } from "./TextFilter";
import { UserFilter, UserFilterDefaultValue } from "./UserFilter";
import { WorkItemStatusFilter, WorkItemStatusFilterDefaultValue } from "./WorkItemStatusFilter";
import { WorkQueueFilter, WorkQueueFilterDefaultValue } from "./WorkQueueFilter";
import { FILTER_TYPE, FilterType } from "./FilterRepository";
import { TagFilter, TagFilterDefaultValue } from "./TagFilter";

interface Indexed {
    [property: string]: any
}

type FilterControlProps = {
    editMode: boolean,
    setEditMode: (editMode: boolean) => void,
    value: any,
    setValue: (value: any, isValid: boolean, isComplete: boolean) => void
}

interface TableColumn {
    selector: string,
    displayName: string,
    sortable?: false | string,
    justify?: 'normal' | 'flex-start' | 'center' | 'flex-end',
    style?: React.CSSProperties,
    render?: (row: any) => JSX.Element
}

type TableColumnFilter = {
    name: string,
    displayName: string,
    type: FilterType
}

const filterControls = {
    [FILTER_TYPE.RULE]: RuleFilter,
    [FILTER_TYPE.FREE_TEXT]: TextFilter,
    [FILTER_TYPE.REGULATORY_IMPACT]: RegulatoryImpactFilter,
    [FILTER_TYPE.USER]: UserFilter,
    [FILTER_TYPE.WORK_ITEM_STATUS]: WorkItemStatusFilter,
    [FILTER_TYPE.PRIORITY]: PriorityFilter,
    [FILTER_TYPE.WORK_QUEUE]: WorkQueueFilter,
    [FILTER_TYPE.TEAM]: TeamFilter,
    [FILTER_TYPE.TAG]: TagFilter,
    [FILTER_TYPE.DATE]: DateFilter,
};

const filterDefaultValues = {
    [FILTER_TYPE.RULE]: RuleFilterDefaultValue,
    [FILTER_TYPE.FREE_TEXT]: TextFilterDefaultValue,
    [FILTER_TYPE.REGULATORY_IMPACT]: RegulatoryImpactFilterDefaultValue,
    [FILTER_TYPE.USER]: UserFilterDefaultValue,
    [FILTER_TYPE.WORK_ITEM_STATUS]: WorkItemStatusFilterDefaultValue,
    [FILTER_TYPE.PRIORITY]: PriorityFilterDefaultValue,
    [FILTER_TYPE.WORK_QUEUE]: WorkQueueFilterDefaultValue,
    [FILTER_TYPE.TEAM]: TeamFilterDefaultValue,
    [FILTER_TYPE.TAG]: TagFilterDefaultValue,
    [FILTER_TYPE.DATE]: DateFilterDefaultValue,
};

export { filterControls, filterDefaultValues }
export type { FilterControlProps, Indexed, TableColumn, TableColumnFilter }