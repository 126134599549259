import { Typography} from "@mui/material";
import { FilterControlProps } from "./types";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { WorkQueue, fetchWorkQueues, useWorkQueues } from "../Api/WorkQueues";
import authClient from "../Auth/auth-client";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";

const WorkQueueFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const { data, isFetching } = useWorkQueues();

    const selectedWorkQueues = value as WorkQueue[];

    const getTextValue = (value: WorkQueue[]) => value. length === 1 
        ? selectedWorkQueues[0].name
        : `${selectedWorkQueues.length} selected`

    const setAutoCompleteValue = (newValue: WorkQueue[]) => {
        setValue(newValue, newValue.length > 0, false);
    }

    if (editMode) {
        return <FilterAutocomplete
            data={data}
            getOptionLabel={o => o.name}
            getTextValue={getTextValue}
            isLoading={isFetching}
            isOptionEqualToValue={(o, v) => o.workQueueId === v.workQueueId}
            selectedOptions={selectedWorkQueues}
            setValue={setAutoCompleteValue}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {selectedWorkQueues.length === 1 
                ? ['=', selectedWorkQueues[0].name].join(' ')
                : `= ${selectedWorkQueues.length} selected`}
        </Typography>
    )
}

const WorkQueueFilterDefaultValue = [] as WorkQueue[];

const converter: FilterToParameterConverter = (name: string, value: WorkQueue[]) => ({
    name: name,
    type: PARAMETER_TYPE.NUMBER,
    value: value.map(wq => wq.workQueueId)
});

registerParameterConverter(FILTER_TYPE.WORK_QUEUE, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: WorkQueue[]) => f.map(wq => wq.workQueueId).join(),
    deserialize: async s => {
        const token = await authClient.getTokenSilently();
        const workQueues = await fetchWorkQueues(token);

        return s
            .split(',')
            .map(v => parseInt(v))
            .filter(v => !isNaN(v))
            .map(v => workQueues.find(wq => wq.workQueueId === v))
            .filter(v => v !== undefined)
            .map(v => ({
                workQueueId: v!.workQueueId,
                name: v!.name
            }) as WorkQueue)
    }
}

registerFilterUrlSerializer(FILTER_TYPE.WORK_QUEUE, serializer);

export { WorkQueueFilter, WorkQueueFilterDefaultValue }