import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { postDciApi } from "../../utils/callDciApi";

type RegulatoryImpact = {
    regulatoryImpactId: number,
    description: string,
    displayIcon: string,
    regulatoryImpactValue: number
}

const fetchRegulatoryImpacts = async (token: string): Promise<RegulatoryImpact[]> => {
    const response = await postDciApi('{allRegulatoryImpacts{regulatoryImpactId,description,displayIcon,regulatoryImpactValue}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.allRegulatoryImpacts as RegulatoryImpact[];
    }
    // TODO: Error handling
}

const useRegulatoryImpacts = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ["regulatory-impacts"],
        queryFn: async () => fetchRegulatoryImpacts(await getAccessTokenSilently())
    })
}

interface Tag {
    colour: string | null
    description: string | null
    isCoreTag: boolean
    name: string
    tagId: number
}

interface CoreTag {
    colour: string | null
    description: string | null
    name: string
    coreTagId: number
}

const fetchTags = async (token: string, includeSystemTags: boolean): Promise<Tag[]> => {
    const response = await postDciApi(`{allTags(${includeSystemTags ? '' : 'where:{isCoreTag:{eq:false}}'}order:{name:ASC}){nodes{isCoreTag tagId,name,colour,description}}}`, token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.allTags.nodes as Tag[];
    }
    // TODO: Error handling
}

const useTags = (includeSystemTags: boolean = true) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: [`tags-${includeSystemTags}`],
        queryFn: async () => fetchTags(await getAccessTokenSilently(), includeSystemTags)
    })
}

const fetchTag = async (tagId: number, token: string): Promise<Tag> => {
    const response = await postDciApi(`{tagById(tagId:${tagId}){tagId,name,colour,description}}`, token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return {
            ...response.data.tagById,
            isCoreTag: false // Not sure if this is a good idea, but didn't want to rename the existing Tag type
        } as Tag;
    }
    // TODO: Error handling
}

const useTag = (tagId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: [ 'tag', tagId],
        queryFn: async () => fetchTag(tagId, await getAccessTokenSilently())
    })
}

const fetchCoreTag = async (tagId: number, token: string): Promise<CoreTag> => {
    const response = await postDciApi(`{coreTagById(coreTagId:${tagId}){coreTagId,name,colour,description}}`, token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.coreTagById as CoreTag;
    }
    // TODO: Error handling
}

const useCoreTag = (coreTagId: number) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: [ 'core-tag', coreTagId],
        queryFn: async () => fetchCoreTag(coreTagId, await getAccessTokenSilently())
    })
}

export { fetchTags, useCoreTag, useRegulatoryImpacts, useTag, useTags }
export type { CoreTag, RegulatoryImpact, Tag }