import { alpha, Collapse, styled } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

const CompactMenu = styled('ul')(({ theme }) => `
    padding: 0px;
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 500;
    font-size: 14px;

    & .MuiSvgIcon-root {
        font-size: 16.5px;
    }

    & .MuiListItemIcon-root {
        min-width: 26.5px;
    }

    & .StandardMenuItem-root {
        cursor: pointer;
        padding: 5px 10px;
        display: flex;
        flex-direction: row;

        &:has( .StandardMenuItem-root) {
            color: red;
        }

        & > .icon {
            min-width: 26.5px;
            display: flex;
            align-items: center;

            & > .MuiSvgIcon-root {
                font-size: 18px;
            }
        }

        & > .expander {
            display: flex;
            align-items: center;

            & > .MuiSvgIcon-root {
                font-size: 20px;
            }
        }

        & > .caption {
            flex-grow: 1
        }
    }

    & .StandardMenuItem-root.selected {
        background-color: ${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)};
        & > .icon {
            color: ${theme.palette.secondary.main}
        }
    }

    & .StandardMenuItem-root:hover {
        text-decoration: 'none';
        background-color:  ${theme.palette.action.hover};
        '@media (hover: none)': {
            background-color: 'transparent';
        }
    }
`);

const CompactMenuHeader = styled('li')(({ theme }) => `
    color: ${alpha(theme.palette.text.primary, 0.6)};
    list-style: none;
    padding: 5px 10px;
`);


interface CompactMenuItemProps {
    icon?: JSX.Element,
    caption: string,
    href?: string
    collapsible?: boolean,
    selected?: boolean,
    shouldDisplay?: boolean
}

interface StandardMenuItemProps {
    icon?: JSX.Element,
    caption: string,
    href?: string,
    level?: number
    selected?: boolean,
    preNavigateAction?: () => void,
    onClick?: React.MouseEventHandler<HTMLLIElement>,
    collapsible?: boolean,
    isOpen?: boolean
}

interface WithLinkProps {
    children?: ReactNode,
    href: string,
}

const WithLink = ({ children, href }: WithLinkProps) => {
    return <Link
        to={href}
        style={{
            textDecoration: 'none',
            color: 'inherit'
        }}
    >
        { children }
    </Link>
}

const CompactMenuItem = ({
    icon,
    caption,
    href,
    selected = false,
    shouldDisplay = true
}: CompactMenuItemProps ) => {
    if (!shouldDisplay) {
        return null;
    }
    
    const className = selected ? 'StandardMenuItem-root selected' : 'StandardMenuItem-root';

    return href
        ? <WithLink href={href}>
            <li className={className}>
                <span style={{ minWidth: '0px' }}></span>
                <span className='icon'>{icon}</span>
                <span className='caption'>{caption}</span>
            </li>
        </WithLink>
        : <li className={className}>
            <span style={{ minWidth: '0px' }}></span>
            <span className='icon'>{icon}</span>
            <span className='caption'>{caption}</span>
        </li>
}

interface WithCollapsibleProps {
    isOpen: boolean,
    children: ReactNode
}

const WithCollapsible = ({ isOpen, children }: WithCollapsibleProps) =>
    <Collapse in={isOpen}>
        { children }
    </Collapse>;

export { CompactMenu, CompactMenuHeader, CompactMenuItem }