import { QueryParameter } from "../../GraphQLShared";
import { FilterNode } from "./TableFilter";

type FilterToParameterConverter = (name: string, value: any) => QueryParameter;

interface FilterUrlSerializer {
    serialize: (value: any) => string
    deserialize: (value: string) => Promise<any>
}

const FILTER_TYPE = {
    RULE: 'Rule',
    FREE_TEXT: 'Free Text',
    USER: 'User',
    REGULATORY_IMPACT: 'Regulatory Impact',
    WORK_ITEM_STATUS: 'Work Item Status',
    PRIORITY: 'Priority',
    WORK_QUEUE: 'Work Queue',
    TEAM: 'Team',
    TAG: 'Tag',
    DATE: 'Date'
} as const;

type ObjectValues<T> = T[keyof T];

type FilterType = ObjectValues<typeof FILTER_TYPE>;

const converterRepository: { filterType: FilterType, converter: FilterToParameterConverter }[] = [];

const urlSerializerRepository: { filterType: FilterType, serializer: FilterUrlSerializer }[] = [];

const getParameterConverter = (filterType: FilterType) => {
    const registration = converterRepository.find(a => a.filterType === filterType);
    if (!registration) {
        console.error(`No converter for ${filterType} has been registered.`);
        return null;
    }

    return registration.converter;
}

const registerParameterConverter = (filterType: FilterType, applicator: FilterToParameterConverter) => {
    const existing = converterRepository.find(a => a.filterType === filterType);
    if (existing) {
        existing.converter = applicator;
    } else {
        converterRepository.push({ filterType: filterType, converter: applicator });
    }
}

const getFilterUrlSerializer = (filterType: FilterType) => {
    const registration = urlSerializerRepository.find(a => a.filterType === filterType);
    if (!registration) {
        console.error(`No serializer for ${filterType} has been registered.`);
        return null;
    }

    return registration.serializer;
}

const registerFilterUrlSerializer = (filterType: FilterType, serializer: FilterUrlSerializer) => {
    const existing = urlSerializerRepository.find(a => a.filterType === filterType);
    if (existing) {
        existing.serializer = serializer;
    } else {
        urlSerializerRepository.push({ filterType: filterType, serializer: serializer });
    }
}

export {
    FILTER_TYPE,
    getFilterUrlSerializer,
    getParameterConverter,
    registerFilterUrlSerializer,
    registerParameterConverter
}
export type { FilterToParameterConverter, FilterType, FilterUrlSerializer }
