import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/MissedAChange.png';

const WNMissedAChange = () => {
    return (
        <>
            <Typography variant='h5'>Missed Something?</Typography>
            <Typography variant='body1'>If you missed the details of a change in Data Analyst or don't have time to read about them now, you can now check back at any time.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Change History" />
            <Typography variant='body1'>Simply open the menu at the top right of the screen and select Change History.</Typography>
        </>
    )
}

export { WNMissedAChange }