import React, { useState, useEffect, useMemo } from 'react';
import { organisationConfig, permissions, tagDefaultColour, workItemReportingEventType } from '../../utils/dciConstants';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Autocomplete,
    AutocompleteChangeReason,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tab,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { 
    CheckboxField,
    ControlButton,
    FieldCaption,
    PageTitle, 
    TextProperty
} from '../DciControls';
import { SingleItem } from '../SingleItem';
import {
    Add,
    Edit,
    Flag,
    Save
} from '@mui/icons-material'
import OrgContainer from '../Organisation/OrgContainer';
import DciLink from '../DciLink';
import dciPaths from '../../utils/dciPaths';
import callDciApi, { postDciApi } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import { encodeURI } from 'js-base64';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import userHasPermission from '../../utils/userHasPermission';
import { PARAMETER_TYPE, QueryParameter } from '../../GraphQLShared';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ChartStoreContext, createChartStore } from '../Reporting/Store/ChartStore';
import { NewChart } from '../Reporting/NewChart';
import { addDays, format, parse } from 'date-fns';
import { ChartDataset } from '../Dashboard/ConfigurableChartCard';
import { AuditList } from '../AuditList';
import { ChartData } from '../../types';
import { AsyncData } from '../Table/Shared';
import { LineItemIdentifier } from '@mui/x-charts';
import Color from 'color';
import { useRegulatoryImpacts } from '../Api/ReactQuery';
import { useWorkQueues } from '../Api/WorkQueues';
import { TagPickerMenu } from '../TagPickerMenu';
import { useSnackbar } from 'notistack';
import { useSearchParamsState } from '../../utils/useSearchParamsState';

const empty = '[Empty]';

const RULE_GROUP = "Set at Rule Group";
const RULE_DEFAULT = "System Default";
const RULE_ORG = "Edited Rule Setting"

type RULE_GROUP = "Set at Rule Group";
type RULE_DEFAULT = "System Default";
type RULE_ORG = "Edited Rule Setting"

const NONE = "None";

type Setting<TSetting> = {
    source: RULE_GROUP | RULE_DEFAULT | RULE_ORG,
    propertyName: string,
    value: TSetting
}

type InheritedSettingProps<TSetting> = {
    caption: string,
    setting: Setting<TSetting>,
    // Could the following two be merged?
    displayTextResolver?: (value: TSetting) => string,
    renderValue?: (setting: Setting<TSetting>) => JSX.Element,
    editMode: boolean,
    setAmendedItem: React.Dispatch<React.SetStateAction<{}>>,
    overridePropertySetter?: () => void,
    children: JSX.Element
}

function InheritedSetting<TSetting>({ caption, setting, displayTextResolver, renderValue, editMode, children, setAmendedItem, overridePropertySetter }: InheritedSettingProps<TSetting>) {
    const overrideOnClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (typeof(overridePropertySetter) === 'function') {
            overridePropertySetter();
        } else {
            setAmendedItem(x => ({ ...x, [setting.propertyName]:typeof(setting.value) === 'object' ? { ...setting.value } : setting.value }));
        }
    };

    const getDisplayText = () => {
        if (displayTextResolver) {
            return displayTextResolver(setting.value);
        }

        switch (typeof setting.value) {
            case 'string':
                return setting.value;
            case 'number':
                return setting.value.toString();
            case 'boolean':
                return setting.value ? 'True' : 'False';
            default:
                return 'No string representation';
        }
    }

    return (
        !editMode || setting.source !== RULE_ORG
        ? <div style={{ marginBottom:10 }}>
            <FieldCaption caption={caption} />
            {
                renderValue
                    ? renderValue(setting)
                    : <Typography style={{ marginRight:5 }} display='inline' variant="body2">{getDisplayText()}</Typography>
            }
            <Typography style={{ marginRight:5, fontSize:'0.8rem', fontStyle:'italic', color:'#aaa' }} display='inline' variant='body2'>{setting.source}</Typography>
            { editMode && <DciLink onClick={overrideOnClick}>Edit</DciLink> }
          </div>
        : <>
            {children}
            { editMode && setting.source === RULE_ORG && 
                <div style={{ marginBottom:10 }}>
                    <DciLink onClick={e => { 
                        e.preventDefault();
                        setAmendedItem(x => ({ ...x, [setting.propertyName]:null }));
                    }}>
                        Set to System Default
                    </DciLink>
                </div>
            }
          </>
    );
};

type RegulatoryImpact = {
    displayIcon: string,
    description: string,
    regulatoryImpactValue: number,
    regulatoryImpactId: number
}

type ReportTest = {
    reportTestId: number,
    ruleDescription: string | null,
    ruleExplanation: string | null,
    reportMessage: string,
    hashColumns: string | null,
    hashKeyColumns: string | null,
    howToFixMessage: string | null,
    rulePriority: number,
    regulatoryImpact: RegulatoryImpact,
    versionNumber: number,
    enabled: boolean,
    [key: string]: any
}

type WorkQueue = {
    workQueueId: number,
    name: string
}

type RuleGroup = {
    ruleGroupId: number
    regulatoryImpact: RegulatoryImpact,
    name: string,
    enabled: boolean,
    canRequestAcceptance: boolean,
    acceptanceSameValueOnly: boolean,
    defaultWorkQueue: WorkQueue,
    rulePriority: number,
    sendAlert: boolean,
    informationRequiredNotificationDelay?: number,
    targetTurnaroundTime?: number,
    [key: string]: any
}

type RuleMetadata = {
    reportTestId: number,
    howToFixMessage: string | null,
    regulatoryImpact: RegulatoryImpact,
    enabled: boolean,
    canRequestAcceptance: boolean,
    acceptanceSameValueOnly: boolean,
    defaultWorkQueue: WorkQueue,
    rulePriority: number | null,
    sendAlert: boolean | null,
    informationRequiredNotificationDelay: number | null,
    targetTurnaroundTime: number | null,
    ruleGroup: RuleGroup | null,
    ruleDescription: string,
    ruleExplanation: string,
    [key: string]: any
}

interface RuleTag {
    isCoreTag: boolean
    tagId: number
    name: string
    description: string | null
    colour: string | null
}

type Rule = {
    reportTest: ReportTest,
    ruleMetadata: RuleMetadata,
    ruleTags: RuleTag[]
}

type RuleDisplayProps = {
    item: Rule,
    refresh: () => void
}

type DisplayObject = {
    ruleGroup: RuleGroup,
    enabled: {
        effectiveValue: boolean,
        itemValue: boolean
    },
    canRequestAcceptance: Setting<boolean>,
    acceptanceSameValueOnly: Setting<boolean>,
    defaultWorkQueue: Setting<WorkQueue | null>,
    rulePriority: Setting<number>,
    sendAlert: Setting<boolean>,
    howToFixMessage: Setting<string>,
    regulatoryImpact: Setting<RegulatoryImpact>,
    informationRequiredNotificationDelay: Setting<number | null>,
    targetTurnaroundTime: Setting<number>,
    ruleDescription: Setting<string>,
    ruleExplanation: Setting<string>,
    ruleTags: RuleTag[]
}

interface TagChipProps extends RuleTag {
    editMode: boolean
    onDelete: (event: any) => void
}

const TagChip = ({ name, colour, isCoreTag, editMode, onDelete }: TagChipProps) => {
    const theme = useTheme();
    const bgColour = Color(isCoreTag ? theme.palette.primary.main : colour ?? tagDefaultColour(theme));
    const textColour = (bgColour.red() * 0.299 + bgColour.green() * 0.587 + bgColour.blue() * 0.114) > 186 ? '#000000' : '#ffffff';

    return <Chip style={{ color:textColour, backgroundColor:bgColour.hex() }} size='small' label={name} onDelete={(editMode && !isCoreTag) ? onDelete : undefined} />
}

const RuleTab = ({ item, refresh }: RuleDisplayProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { data: regulatoryImpacts, isFetching: regulatoryImpactsLoading } = useRegulatoryImpacts();
    const { data: workQueues, isFetching: workQueuesLoading } = useWorkQueues();

    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [ editMode, setEditMode ] = useState(false);
    const [ amendedItem, setAmendedItem ] = useState<{ [key: string]: any }>({});
    const [ displayObject, setDisplayObject ] = useState<DisplayObject | null>(null)
    const [ displayObjectCalculated, setDisplayObjectCalculated ] = useState(false);
    
    const [ ruleGroupIsOpen, setRuleGroupIsOpen ] = useState(false);
    const [ ruleGroupsLoading, setRuleGroupsLoading ] = useState(true);
    // { ruleGroupId:0, name:NONE }
    const [ ruleGroups, setRuleGroups ] = useState<RuleGroup[]>([]);

    const [ defaultWorkQueueIsOpen, setDefaultWorkQueueIsOpen ] = useState(false);
    const [ regulatoryImpactIsOpen, setRegulatoryImpactIsOpen ] = useState(false)

    const [ defaultTargetTurnaroundTime, setDefaultTargetTurnaroundTime ] = useState<number | null>(null);

    const [ isTagPickerMenuOpen, setIsTagPickerMenuOpen ] = useState(false);
    const tagPickerMenuRef = React.useRef<HTMLButtonElement>(null);

    // ==============================================================================

    const xxxRoot = <T,>(propertyName: string, fallbackValue: T): Setting<T> => {
        return xxxGetAmendedRuleSetting(propertyName, fallbackValue);
    };

    const xxxGetAmendedRuleSetting = <T,>(propertyName: string, fallbackValue: T): Setting<T> => {
        if (propertyName in amendedItem) {
            return amendedItem[propertyName] === null ? xxxGetAmendedRuleGroupSetting(propertyName, fallbackValue) : { value:amendedItem[propertyName], source:RULE_ORG, propertyName };
        }

        return xxxGetRuleSetting(propertyName, fallbackValue);
    };

    const xxxGetRuleSetting = <T,>(propertyName: string, fallbackValue: T): Setting<T> => item.ruleMetadata[propertyName] === null ? xxxGetAmendedRuleGroupSetting(propertyName, fallbackValue) : { value:item.ruleMetadata[propertyName], source:RULE_ORG, propertyName };

    const xxxGetAmendedRuleGroupSetting = <T,>(propertyName: string, fallbackValue: T): Setting<T> => {
        if (typeof(amendedItem.ruleGroup) === 'undefined') {
            return xxxGetRuleGroupSetting(propertyName, fallbackValue);
        } else if (amendedItem.ruleGroup === null) {
            return xxxGetReportTestSetting(propertyName, fallbackValue);
        }

        return amendedItem.ruleGroup[propertyName] === null ? xxxGetReportTestSetting(propertyName, fallbackValue) : { value:amendedItem.ruleGroup[propertyName], source:RULE_GROUP, propertyName };
    };

    const xxxGetRuleGroupSetting = <T,>(propertyName: string, fallbackValue: T): Setting<T> => {
        if (item.ruleMetadata.ruleGroup === null || typeof (item.ruleMetadata.ruleGroup[propertyName]) === 'undefined' || item.ruleMetadata.ruleGroup[propertyName] === null) {
            return xxxGetReportTestSetting(propertyName, fallbackValue);
        }

        return { value:item.ruleMetadata.ruleGroup[propertyName], source:RULE_GROUP, propertyName };
    }

    const xxxGetReportTestSetting = <T,>(propertyName: string, fallbackValue: T): Setting<T> => {
        if (typeof(item.reportTest[propertyName]) === 'undefined' || item.reportTest[propertyName] === null) {
            return { value:fallbackValue, source:RULE_DEFAULT, propertyName };
        }

        return { value:item.reportTest[propertyName], source:RULE_DEFAULT, propertyName };
    };

    // ==============================================================================

    const getEnabledDisplayProperty = () => {
        let ruleGroupValue = true;
        if (typeof(amendedItem.ruleGroup) === 'undefined') {
            // Check original item for rule group
            ruleGroupValue = item.ruleMetadata.ruleGroup === null ? true : item.ruleMetadata.ruleGroup.enabled;
        } else if (amendedItem.ruleGroup === null) {
            // Leave value as true - this will allow for rule-level setting to take effect
        } else {
            ruleGroupValue = amendedItem.ruleGroup.enabled;
        }

        const ruleValue = typeof(amendedItem.enabled) === 'undefined' ? item.ruleMetadata.enabled : amendedItem.enabled;

        return {
            effectiveValue: ruleGroupValue && ruleValue,
            itemValue: ruleValue
        };
    };

    const serializePropertyValue = (prop: any): string => {
        if (prop === null) {
            return 'null';
        } else if (typeof(prop) === 'string') {
            return `"${prop}"`;
        } else if (Array.isArray(prop)) {
            return `[${prop.map(e => serializePropertyValue(e)).join()}]`
        } else {
            return prop.toString();
        }
    };

    const saveChanges = async () => {
        const entityToSave = {
            ...item.ruleMetadata,
            ...amendedItem,
            ruleGroupId: typeof(amendedItem.ruleGroup) === 'undefined'
                ? (item.ruleMetadata.ruleGroup && item.ruleMetadata.ruleGroup.ruleGroupId)
                : (amendedItem.ruleGroup && amendedItem.ruleGroup.ruleGroupId),
            defaultWorkQueueId: typeof(amendedItem.defaultWorkQueue) === 'undefined'
                ? (item.ruleMetadata.defaultWorkQueue && item.ruleMetadata.defaultWorkQueue.workQueueId)
                : (amendedItem.defaultWorkQueue && amendedItem.defaultWorkQueue.workQueueId),
            regulatoryImpactId: typeof(amendedItem.regulatoryImpact) === 'undefined'
                ? (item.ruleMetadata.regulatoryImpact && item.ruleMetadata.regulatoryImpact.regulatoryImpactId)
                : (amendedItem.regulatoryImpact && amendedItem.regulatoryImpact.regulatoryImpactId),
            tags: ((amendedItem.ruleTags ?? item.ruleTags) as RuleTag[]).filter(t => !t.isCoreTag).map(t => t.tagId)
        } as any;

        if (entityToSave.howToFixMessage) {
            entityToSave.howToFixMessage = entityToSave.howToFixMessage
                .replaceAll('\\', '\\\\')
                .replaceAll('\n', '\\n')
                .replaceAll('"', '\\"');
        }

        if (entityToSave.ruleDescription) {
            entityToSave.ruleDescription = entityToSave.ruleDescription
                .replaceAll('\\', '\\\\')
                .replaceAll('\n', '\\n')
                .replaceAll('"', '\\"');
        }

        if (entityToSave.ruleExplanation) {
            entityToSave.ruleExplanation = entityToSave.ruleExplanation
                .replaceAll('\\', '\\\\')
                .replaceAll('\n', '\\n')
                .replaceAll('"', '\\"');
        }

        delete entityToSave.ruleGroup;
        delete entityToSave.defaultWorkQueue;
        delete entityToSave.regulatoryImpact;
        delete entityToSave.ruleTags;

        const token = await getAccessTokenSilently();
        postDciApi(`mutation{editRule(rule:{${Object.keys(entityToSave).map(x => `${x}:${serializePropertyValue(entityToSave[x])}`).join()}}){reportTestId}}`, token)
        .then(body => {
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setEditMode(false);
                setAmendedItem({});
                refresh();
                enqueueSnackbar('Saved', { variant:'success' });
            }
        })
        .catch(error => {
            console.error(`[SingleRule] editRule: ${error}`);
        });
    };

    const changesMade = () => {
        if (Object.keys(amendedItem).includes('ruleTags')) {
            const amendedRuleTags = amendedItem.ruleTags as RuleTag[];

            if (amendedRuleTags.length !== item.ruleTags.length) {
                return true;
            }

            if (amendedRuleTags.some(at => !item.ruleTags.some(it => it.isCoreTag === at.isCoreTag && it.tagId === at.tagId))) {
                return true;
            }
        }

        return Object
            .keys(amendedItem)
            .filter(p => p !== 'ruleTags')
            .some(element => item.ruleMetadata[element] !== amendedItem[element]);
    };

    const cancelChanges = () => {
        setEditMode(false);
        setAmendedItem({});
    };

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_RULE);

    const defaultRegulatoryImpact = useMemo(() => ({
        displayIcon: 'grey',
        description: 'Not defined',
        regulatoryImpactId: 3,
        regulatoryImpactValue: 3
    }) as RegulatoryImpact, []);

    useEffect(() => {
        const calculateDisplayObject = async () => {
            let defaultTAT = defaultTargetTurnaroundTime;
            if (defaultTAT === null) {
                const token = await getAccessTokenSilently();
                const apiResult = await callDciApi('{organisationConfiguration{configId,value}}', token);
                if (apiResult.errors) {
                    console.error(`[SingleRule:calculateDisplayObject] Fetch error for organisationConfiguration. Setting default Target Turnaround Time to 0.`);
                    setDefaultTargetTurnaroundTime(0);
                    defaultTAT = 0;
                } else {
                    const configItem = apiResult.data.organisationConfiguration.find((x: any) => x.configId === organisationConfig.DEFAULT_TARGET_TURNAROUND_TIME);
                    if (configItem) {
                        let value = parseInt(configItem.value);
                        if (isNaN(value) || value < 0) {
                            console.error(`[SingleRule:calculateDisplayObject] Configuration value for Default Target Turnaround Time is not a valid number. Setting to 0.`);
                            value = 0;
                        }

                        setDefaultTargetTurnaroundTime(value);
                        defaultTAT = value;
                    } else {
                        setDefaultTargetTurnaroundTime(0);
                        defaultTAT = 0;
                    }
                }
            }
            
            setDisplayObject({
                ruleGroup: typeof(amendedItem.ruleGroup) === 'undefined' ? item.ruleMetadata.ruleGroup : amendedItem.ruleGroup,
                enabled: getEnabledDisplayProperty(),
                canRequestAcceptance: xxxRoot('canRequestAcceptance', false),
                acceptanceSameValueOnly: xxxRoot('acceptanceSameValueOnly', true),
                defaultWorkQueue: xxxRoot('defaultWorkQueue', null),
                rulePriority: xxxRoot('rulePriority', item.reportTest.rulePriority),
                sendAlert: xxxRoot('sendAlert', false),
                howToFixMessage: xxxRoot('howToFixMessage', 'fallback'),
                regulatoryImpact: xxxRoot('regulatoryImpact', defaultRegulatoryImpact),
                informationRequiredNotificationDelay: xxxRoot('informationRequiredNotificationDelay', null),
                targetTurnaroundTime: xxxRoot('targetTurnaroundTime', defaultTAT),
                ruleDescription: xxxRoot('ruleDescription', item.reportTest.ruleDescription ?? ''),
                ruleExplanation: xxxRoot('ruleExplanation', item.reportTest.ruleExplanation ?? ''),
                ruleTags: typeof amendedItem.ruleTags === 'undefined' ? item.ruleTags : amendedItem.ruleTags
            });
    
            setDisplayObjectCalculated(true);
        }

        calculateDisplayObject();
    }, [ amendedItem, item ]);

    useEffect(() => {
        let active = true;
    
        if (!ruleGroupsLoading) {
          return undefined;
        }
    
        (async () => {
            const token = await getAccessTokenSilently();
            callDciApi('{ruleGroups{nodes{ruleGroupId,name,enabled,canRequestAcceptance,acceptanceSameValueOnly,defaultWorkQueue{workQueueId,name}rulePriority,regulatoryImpact{regulatoryImpactId,description,displayIcon,regulatoryImpactValue},sendAlert,informationRequiredNotificationDelay,targetTurnaroundTime}}}', token)
            .then(body => {
                if (active && !body.errors) {
                    setRuleGroups(w => [ ...w, ...body.data.ruleGroups.nodes ]);
                    setRuleGroupsLoading(false);
                }
            });
        })();
    
        return () => {
          active = false;
        };
    }, [ ruleGroupsLoading ]);

    const activeWorkItemsForRuleUrl = useMemo(() => {
        var reportParameters = {
            title: `Active Work Items for Rule: ${item.reportTest.reportTestId}`,
            parameters: [
                {
                    name: "fixedFilter.workItemStatusId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ 1, 2, 3, 4, 6, 8 ]
                },
                {
                    name: "fixedFilter.reportTestId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ item.reportTest.reportTestId ]
                }
            ] as QueryParameter[]
        };

        const base64 = encodeURI(JSON.stringify(reportParameters));
        return `/workitems?q=${base64}`
    }, [ item.reportTest.reportTestId ]);

    const tagOnDelete = (tag: RuleTag) => {
        if (!Object.keys(amendedItem).includes('ruleTags')) {
            amendedItem.ruleTags = [ ...item.ruleTags ]
        }

        setAmendedItem(x => ({ ...x, ruleTags: (x.ruleTags as RuleTag[]).filter(t => t.isCoreTag !== tag.isCoreTag || t.tagId !== tag.tagId) }));
    }

    if (!defaultTargetTurnaroundTime || !displayObject || !displayObjectCalculated) {
        return <Stack sx={{ height:'100%' }} alignItems='center' justifyContent='center'>Loading...</Stack>
    }

    return <Stack style={{ height:'100%', width:'100%' }}>
        <Box style={{ flexGrow:1, overflowY:'auto' }}>
            <Box style={{ float:'right' }}>
                {hasEditPermission && !editMode && <Button startIcon={<Edit />} onClick={() => setEditMode(true)}>Edit</Button>}
            </Box>
            <Box component="span" style={{ marginRight:'10px' }}>
                <PageTitle style={{ display:'inline' }} title={`Rule: ${item.reportTest.reportTestId}`} />
            </Box>
            <Box component="span">
                <DciLink href={activeWorkItemsForRuleUrl}>View Work Items for this Rule</DciLink>
            </Box>
            <FieldCaption style={{ paddingTop:'5px' }} caption='Tags' />
            <Stack sx={{ paddingBottom:'10px' }} direction='row' gap={1}>
                { displayObject.ruleTags.map(t => <TagChip key={`${t.isCoreTag}-${t.tagId}`} {...t} editMode={editMode} onDelete={() => tagOnDelete(t)} />) }
                { editMode ?
                    <>
                        <IconButton ref={tagPickerMenuRef} sx={{ padding:'0px' }} size='small' onClick={() => setIsTagPickerMenuOpen(true)}><Add /></IconButton>
                        <TagPickerMenu
                            isOpen={isTagPickerMenuOpen}
                            close={() => setIsTagPickerMenuOpen(false)}
                            anchorEl={tagPickerMenuRef.current}
                            omitTags={displayObject.ruleTags}
                            showSystemTags={false}
                            selectTag={t => {
                                setAmendedItem(e => ({ ...e, ruleTags:[ ...(e.ruleTags ?? item.ruleTags), t ] }));
                                setIsTagPickerMenuOpen(false);
                            }}
                        />
                    </> : null
                }
            </Stack>
            <InheritedSetting caption='Description' setAmendedItem={setAmendedItem} editMode={editMode} setting={displayObject.ruleDescription}>
                <TextField
                    variant='standard'
                    defaultValue={displayObject.ruleDescription.value}
                    label='Description'
                    onChange={e => { const value = e.target.value; setAmendedItem(x => ({ ...x, ruleDescription:value }))}}
                />
            </InheritedSetting>
            <InheritedSetting caption='Explanation' setAmendedItem={setAmendedItem} editMode={editMode} setting={displayObject.ruleExplanation}>
                <TextField
                    multiline
                    variant='standard'
                    defaultValue={displayObject.ruleExplanation.value}
                    label='Explanation'
                    onChange={e => { const value = e.target.value; setAmendedItem(x => ({ ...x, ruleExplanation:value }))}}
                />
            </InheritedSetting>
            { editMode
            ? <Autocomplete<RuleGroup>
                    disabled={!editMode}
                    id="rule-group"
                    style={{ width: 300, marginTop:'10px' }}
                    open={ruleGroupIsOpen}
                    onChange={(obj: React.ChangeEvent<{}>, value: RuleGroup | null, reason: AutocompleteChangeReason) => setAmendedItem({ ...amendedItem, ruleGroup:value === null || value.ruleGroupId === 0 ? null : value })}
                    onOpen={() => setRuleGroupIsOpen(true)}
                    onClose={() => setRuleGroupIsOpen(false)}
                    isOptionEqualToValue={(option, value) => option.ruleGroupId === value.ruleGroupId}
                    getOptionLabel={option => option.name}
                    value={ ruleGroupsLoading ? null : displayObject.ruleGroup}
                    options={ruleGroups}
                    loading={ruleGroupsLoading}
                    renderInput={(params) => (
                        <TextField
                            variant='standard'
                            {...params}
                            label="Rule Group"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {ruleGroupsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                                ),
                            }}
                        />
                    )}
                />
            : item.ruleMetadata.ruleGroup === null
                    ? <TextProperty light caption='Rule Group' value='No Group' />
                    : <div style={{ marginBottom:10 }}>
                        <FieldCaption caption='Rule Group' />
                        <DciLink href={dciPaths.ruleGroup.buildLink(item.ruleMetadata.ruleGroup!.ruleGroupId)}>{item.ruleMetadata.ruleGroup.name}</DciLink>
                    </div>
            }
            { editMode
            ? <>
                    <CheckboxField
                        disabled={!editMode}
                        displayName='Enabled' 
                        id='enabled'
                        value={displayObject.enabled.itemValue} 
                        onChange={e => setAmendedItem({ ...amendedItem, enabled:e.target.checked})}
                    />
                    { displayObject.enabled.itemValue === true && displayObject.enabled.effectiveValue === false && <Typography variant='caption'>Note: This rule will still be disabled because of the 'Enabled' setting in the Rule Group</Typography> }
                </>
            : <TextProperty caption='Enabled' value={displayObject.enabled.effectiveValue ? 'Yes' : 'No'} />
            }
            <InheritedSetting caption='How to Fix' setAmendedItem={setAmendedItem} editMode={editMode} setting={displayObject.howToFixMessage}>
                <TextField
                    variant='standard'
                    multiline
                    defaultValue={displayObject.howToFixMessage.value}
                    label='How to Fix'
                    onChange={e => { const value = e.target.value; setAmendedItem(x => ({ ...x, howToFixMessage:value }))}}
                />
            </InheritedSetting>
            <InheritedSetting caption='Override Requests Allowed' setAmendedItem={setAmendedItem} editMode={editMode} setting={displayObject.canRequestAcceptance} displayTextResolver={value => value === true ? 'Yes' : 'No'}>
                <CheckboxField
                    disabled={item.reportTest.hashKeyColumns === null}
                    displayName='Override Requests Allowed' 
                    id='can-request-override'
                    value={displayObject.canRequestAcceptance.value} 
                    onChange={e => {
                        setAmendedItem({
                            ...amendedItem,
                            canRequestAcceptance: e.target.checked,
                            acceptanceSameValueOnly: e.target.checked && displayObject.acceptanceSameValueOnly.value
                        });
                    }}
                />
            </InheritedSetting>
            <InheritedSetting caption='Overrides Valid for Same Values Only' setAmendedItem={setAmendedItem} editMode={editMode} setting={displayObject.acceptanceSameValueOnly} displayTextResolver={value => value === true ? 'Yes' : 'No'}>
                <CheckboxField
                    disabled={item.reportTest.hashKeyColumns === null || item.reportTest.hashColumns === null || !displayObject.canRequestAcceptance.value}
                    displayName='Overrides Valid for Same Values Only' 
                    id='override-same-values-only'
                    value={displayObject.acceptanceSameValueOnly.value} 
                    onChange={e => setAmendedItem({ ...amendedItem, acceptanceSameValueOnly:e.target.checked})}
                />
            </InheritedSetting>
            <InheritedSetting 
                caption='Regulatory Impact' 
                setAmendedItem={setAmendedItem} 
                editMode={editMode} 
                setting={displayObject.regulatoryImpact} 
                displayTextResolver={value => value === null ? 'None' : value.description}
                //overridePropertySetter={() => setAmendedItem(x => ({ ...x, regulatoryImpact:displayObject.regulatoryImpact.value === null ? { ...regulatoryImpacts[0] } : { ...displayObject.regulatoryImpact.value } }))}
                overridePropertySetter={() => setAmendedItem(x => ({ ...x, regulatoryImpact:displayObject.regulatoryImpact.value === null ?  { ...regulatoryImpacts![0] } : { ...displayObject.regulatoryImpact.value } }))}
                renderValue={ setting =>
                    <>
                        <Flag style= {{ 
                            color: setting.value.displayIcon,
                            marginBottom: -7,
                            marginLeft: -4
                        }} />
                        <span style={{ marginRight:'5px' }}>{setting.value.description}</span>
                    </>
                }
            >
                <Autocomplete
                    disabled={!editMode}
                    id="regulatory-impact"
                    style={{ width: 300, marginTop:'10px' }}
                    open={regulatoryImpactIsOpen}
                    onChange={(obj: React.ChangeEvent<{}>, value: RegulatoryImpact | null, reason: AutocompleteChangeReason) => setAmendedItem({ ...amendedItem, regulatoryImpact:value })}
                    onOpen={() => setRegulatoryImpactIsOpen(true)}
                    onClose={() => setRegulatoryImpactIsOpen(false)}
                    isOptionEqualToValue={(option, value) => option.regulatoryImpactId === value.regulatoryImpactId}
                    getOptionLabel={option => option.description}
                    renderOption={ (props, option) =>
                        <li {...props}>
                            <Flag style= {{ 
                                color: option.displayIcon,
                                marginBottom: -2,
                                marginRight: 5
                            }} />
                            {option.description}
                        </li>
                    }
                    //value={ regulatoryImpactsLoading ? regulatoryImpacts[0] : displayObject.regulatoryImpact.value}
                    value={displayObject.regulatoryImpact.value}
                    options={regulatoryImpacts ?? []}
                    loading={regulatoryImpactsLoading}
                    renderInput={(params) => (
                        <TextField
                            variant='standard'
                            {...params}
                            label="Regulatory Impact"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {regulatoryImpactsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                                ),
                            }}
                        />
                    )}
                />
            </InheritedSetting>
            <InheritedSetting 
                caption='Default Work Queue' 
                setAmendedItem={setAmendedItem} 
                editMode={editMode} 
                setting={displayObject.defaultWorkQueue} 
                displayTextResolver={value => value === null ? 'None' : value.name}
                //overridePropertySetter={() => setAmendedItem(x => ({ ...x, defaultWorkQueue:displayObject.defaultWorkQueue.value === null ? { ...workQueues[0] } : { ...displayObject.defaultWorkQueue.value } }))}
                overridePropertySetter={() => setAmendedItem(x => ({ ...x, defaultWorkQueue:displayObject.defaultWorkQueue.value === null ? { ...workQueues![0] } : { ...displayObject.defaultWorkQueue.value } }))}
            >
                <Autocomplete
                    disabled={!editMode}
                    id="default-work-queue"
                    style={{ width: 300, marginTop:'10px' }}
                    open={defaultWorkQueueIsOpen}
                    onChange={(obj: React.ChangeEvent<{}>, value: WorkQueue | null, reason: AutocompleteChangeReason) => setAmendedItem({ ...amendedItem, defaultWorkQueue:value === null || value.workQueueId === 0 ? null : value })}
                    onOpen={() => setDefaultWorkQueueIsOpen(true)}
                    onClose={() => setDefaultWorkQueueIsOpen(false)}
                    isOptionEqualToValue={(option, value) => option.workQueueId === value.workQueueId}
                    getOptionLabel={option => option.name}
                    //value={ workQueuesLoading ? workQueues[0] : displayObject.defaultWorkQueue.value}
                    value={displayObject.defaultWorkQueue.value}
                    options={workQueues ?? []}
                    loading={workQueuesLoading}
                    renderInput={(params) => (
                        <TextField
                            variant='standard'
                            {...params}
                            label="Default Work Queue"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <>
                                    {workQueuesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                                ),
                            }}
                        />
                    )}
                />
            </InheritedSetting>
            <InheritedSetting caption='Priority' setAmendedItem={setAmendedItem} editMode={editMode} setting={displayObject.rulePriority}>
                <FormControl disabled={!editMode} style={{ marginTop:'10px' }} variant='standard'>
                    <InputLabel id="rule-priority">Priority</InputLabel>
                    <Select
                        labelId="rule-priority-label"
                        id="rule-priority-select"
                        value={displayObject.rulePriority.value}
                        onChange={e => setAmendedItem({ ...amendedItem, rulePriority:e.target.value })}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </FormControl>
            </InheritedSetting>
            <InheritedSetting caption='Target Turnaround Time' setAmendedItem={setAmendedItem} editMode={editMode} setting={displayObject.targetTurnaroundTime} displayTextResolver={value => `${value} day${value > 1 ? 's' : ''}`}>
                <TextField
                    variant='standard'
                    type='number'
                    defaultValue={displayObject.targetTurnaroundTime.value}
                    label='Target Turnaround Time'
                    onChange={e => { const value = e.target.value; setAmendedItem(x => ({ ...x, targetTurnaroundTime:value === '' ? '' : parseInt(value)}))}}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">days</InputAdornment>,
                        inputProps: { min: 1 }
                    }}
                />
            </InheritedSetting>
        </Box>
        <Box>
        { editMode &&
            <>
                <ControlButton style={{ marginBottom:'0px' }} onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                <ControlButton style={{ marginBottom:'0px' }} onClick={cancelChanges} variant='contained'>Cancel</ControlButton>
            </>
        }
        </Box>
    </Stack>
}

const onMarkClick = (lineItemIdentifier: LineItemIdentifier, chartData: AsyncData<ChartData>, reportTestId: number, navigate: NavigateFunction) => {
    if (!chartData.data || !lineItemIdentifier.dataIndex) {
        return;
    }

    const on = chartData.data.identifiers[lineItemIdentifier.dataIndex]
    const url = `${dciPaths.reporting.buildLink()}?event=${lineItemIdentifier.seriesId === 0 ? 'Created' : 'Resolved'}&reportType=Item+List&on=${on}&filter.reportTestId=${reportTestId}`;
    
    navigate(url);
}

const StatisticsTab = ({ reportTestId }: { reportTestId: number }) => {
    const navigate = useNavigate();
    const [ store ] = useState(() => {
        const now = new Date();
        const end = parse(format(now, 'yyyy-MM-dd'), 'yyyy-MM-dd', now);
        const start = addDays(end, -30 + 1); // Inclusive of today
        
        return createChartStore({ 
            graphQLQueryName: 'timeSeriesWorkItemReport' 
        }, {
            fixedParameters: [
                {
                    name: 'datasets',
                    type: PARAMETER_TYPE.CHART_DATASET_ARRAY,
                    value: [
                        {
                            metricType: 'EVENT_COUNT',
                            eventTypes: [ 1 ],
                            colour: '1',
                            label: 'Created',
                            id: 0
                        },
                        {
                            metricType: 'EVENT_COUNT',
                            eventTypes: [
                                workItemReportingEventType.OVERRIDE_APPROVED,
                                workItemReportingEventType.OVERRIDE_APPROVED_SAME_DATA_ONLY,
                                workItemReportingEventType.CORRECTED,
                                workItemReportingEventType.CORRECTED_AT_SOURCE
                            ],
                            colour: '3',
                            label: 'Resolved',
                            id: 1
                        }
                    ] as ChartDataset[]
                },
                {
                    name: 'fixedFilter.reportingEventDate',
                    type: PARAMETER_TYPE.DATE,
                    value: { 
                        onOrAfter: start,
                        onOrBefore: end
                    }
                },
                {
                    name: 'fixedFilter.reportTestId',
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ reportTestId ]
                }
            ]
        })
    });

    return <ChartStoreContext.Provider value={store}>
        <NewChart style={{ height:'350px' }} onMarkClick={(_, lii) => onMarkClick(lii, store.getState().chartData, reportTestId, navigate)} />
    </ChartStoreContext.Provider>
}

const RuleDisplay = ({ item, refresh }: RuleDisplayProps) => {
    const [ tabIndex, setTabIndex ] = useSearchParamsState('tab', 'rule');

    if (['rule', 'statistics', 'audit'].indexOf(tabIndex) === -1) {
        setTabIndex('rule');
    }

    return (
        <TabContext value={tabIndex}>
            <Stack component={Paper} sx={{ height:'100%' }}>
                <Box>
                    <TabList onChange={(_, v) => setTabIndex(v)}>
                        <Tab label='Rule' value='rule' />
                        <Tab label='Statistics' value='statistics' />
                        <Tab label='Audit' value='audit' />
                    </TabList>
                </Box>
                <TabPanel sx={{ flexGrow:1, overflow:'hidden' }} value='rule'>
                    <RuleTab item={item} refresh={refresh} />
                </TabPanel>
                <TabPanel value='statistics'>
                    <StatisticsTab reportTestId={item.reportTest.reportTestId} />
                </TabPanel>
                <TabPanel sx={{ padding:'5px 0px 0px', flexGrow:1, overflow:'hidden' }} value='audit'>
                    <AuditList type='RuleMetadata' entityKey={[{ fieldName:'ReportTestId', fieldValue:item.reportTest.reportTestId }]} />
                </TabPanel>
            </Stack>
        </TabContext>
    )
};

type RuleViewProps = {
    id: number
}

const RuleView = ({ id }: RuleViewProps) => {
    return (
        <OrgContainer>
            <SingleItem<Rule>
                queryName={'ruleById'}
                queryParameters={`ruleId:${id}`}
                queryColumns={'{ruleTags{isCoreTag tagId name description colour}reportTest{reportTestId,ruleDescription,ruleExplanation,reportMessage,howToFixMessage,rulePriority,hashColumns,hashKeyColumns,regulatoryImpact{displayIcon,description,regulatoryImpactValue,regulatoryImpactId}versionNumber,enabled}ruleMetadata{reportTestId,howToFixMessage,ruleDescription,ruleExplanation,regulatoryImpact{displayIcon,description,regulatoryImpactValue,regulatoryImpactId},enabled,canRequestAcceptance,acceptanceSameValueOnly,defaultWorkQueue{workQueueId,name}rulePriority,sendAlert,informationRequiredNotificationDelay,targetTurnaroundTime,ruleGroup{ruleGroupId,regulatoryImpact{displayIcon,description,regulatoryImpactValue,regulatoryImpactId},name,enabled,canRequestAcceptance,acceptanceSameValueOnly,defaultWorkQueue{workQueueId,name}rulePriority,sendAlert,informationRequiredNotificationDelay,targetTurnaroundTime}}}'}
                ItemComponent={({item, refresh}) => <RuleDisplay item={item} refresh={refresh} />}
            />
        </OrgContainer>
    );
};

export { RuleView }