import { Box, Chip, ClickAwayListener, Divider, IconButton, Input, MenuItem, MenuList, Paper, Popper, Stack, Typography, useTheme } from "@mui/material"
import { Tag, useTags } from "./Api/ReactQuery"
import { useState } from "react"
import { Clear, Height, Search } from "@mui/icons-material"
import DciLink from "./DciLink"
import Color from "color"
import { useStore } from "zustand"
import { appGlobalStore } from "../AppGlobalStore"
import userHasPermission from "../utils/userHasPermission"
import { permissions, tagDefaultColour } from "../utils/dciConstants"
import { CreateTagDialog } from "./CreateTagDialog"
import { useQueryClient } from "@tanstack/react-query"

const TagChip = ({ name, colour, isCoreTag }: Tag) => {
    const theme = useTheme();
    const bgColour = Color(isCoreTag ? tagDefaultColour(theme) : colour ?? tagDefaultColour(theme));
    const textColour = (bgColour.red() * 0.299 + bgColour.green() * 0.587 + bgColour.blue() * 0.114) > 186 ? '#000000' : '#ffffff';

    return <Chip style={{ color:textColour, backgroundColor:bgColour.hex() }} size='small' label={name} />
}

interface TagPickerMenuProps {
    close: () => void
    isOpen: boolean
    anchorEl: HTMLElement | null
    omitTags?: Tag[]
    showSystemTags: boolean
    selectTag?: (tag: Tag) => void
    showNewTag?: boolean
}

const TagPickerMenu = ({ close, isOpen, omitTags = [], anchorEl, showSystemTags, selectTag = (t: Tag) => {}, showNewTag = true }: TagPickerMenuProps) => {
    const queryClient = useQueryClient();
    const { isFetching, data, isError } = useTags(showSystemTags);
    const [ filterText, setFilterText ] = useState('');
    const [ createTagDialogIsOpen, setCreateTagDialogIsOpen ] = useState(false);
    const currentUser = useStore(appGlobalStore, s => s.currentUser);

    const canCreateTags = userHasPermission(currentUser, permissions.CREATE_TAGS);

    const closeMenu = () => {
        setFilterText('');
        close();
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            closeMenu();
        } else if (event.key === 'Escape') {
            closeMenu();
        }
    }

    return <Popper open={isOpen} anchorEl={anchorEl} popperOptions={{ placement:'bottom-start' }}>
        <Paper sx={{ maxHeight:'560px', width:'240px' }}>
            <ClickAwayListener onClickAway={closeMenu}>
                <Stack style={{ height:'100%', width:'100%' }}>
                    <Stack sx={{ padding:'0px 5px' }} direction='row' alignItems='center' gap={1}>
                        <Search color='action' />
                        <Input
                            autoFocus
                            onKeyDown={handleKeyDown}
                            placeholder='Filter tags'
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                            disableUnderline
                        />
                        { filterText === '' ? null :
                            <IconButton
                                sx={theme => ({
                                    '&:hover':{
                                        backgroundColor:'inherit',
                                        color:theme.palette.warning.main
                                    }
                                })}
                                size='small'
                                onClick={() => setFilterText('')}
                            >
                                <Clear sx={{ fontSize: 16 }} />
                            </IconButton>
                        }
                    </Stack>
                    <Divider />
                    <MenuList style={{ height:'350px', flexGrow:1, overflowY:'auto' }}>
                        { !data || isFetching || isError  ? null :
                            data
                                .filter(t => !omitTags.some(ot => ot.isCoreTag === t.isCoreTag && ot.tagId === t.tagId))
                                .filter(t => filterText === '' ? true : t.name.toLowerCase().includes(filterText.toLowerCase()))
                                .map(t => <MenuItem sx={{ paddingTop:'4px', paddingBottom:'4px' }} onClick={() => selectTag(t)} key={`${t.isCoreTag}-${t.tagId}`}><TagChip {...t} /></MenuItem>)
                        }
                    </MenuList>
                    {
                        (!showNewTag || !canCreateTags) ? null : <>
                            <Divider />
                            <Box sx={{ padding:'10px' }}>
                                <DciLink onClick={e => { e.preventDefault(); setCreateTagDialogIsOpen(true); }}>New tag...</DciLink>
                                <CreateTagDialog
                                    isOpen={createTagDialogIsOpen}
                                    close={t => {
                                        setCreateTagDialogIsOpen(false);
                                        if (t) {
                                            queryClient.invalidateQueries({ queryKey: [`tags-${showSystemTags}`] })
                                            selectTag(t);
                                        }
                                    }}
                                />
                            </Box>
                        </>
                    }
                </Stack>
            </ClickAwayListener>
        </Paper>
    </Popper>
}

export { TagChip, TagPickerMenu }