import React, { CSSProperties } from 'react';
import {
    Box,
    Button,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Input,
    InputAdornment,
    styled,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import DciLink from './DciLink';

const classes = {
    root: {
        marginBottom:'10px'
    } as SxProps<Theme>,
    needHeadRoom: {
        marginTop:'10px'
    } as SxProps<Theme>
}

type PageTitleProps = {
    title: string,
    style?: React.CSSProperties
};

const PageTitle: React.FC<PageTitleProps> = ({ title, style }) => <Typography style={style} variant='h5' paragraph>{title}</Typography>;

type FieldCaptionProps = {
    caption: string,
    style?: CSSProperties
};

const FieldCaption: React.FC<FieldCaptionProps> = ({ caption, style = {} }) => <Typography style={style} variant='caption' display='block'>{caption}</Typography>

const LightTypography = styled(Typography)`
    root {
        color:lightgrey
    }
`;

type TextPropertyProps = {
    caption: string,
    value: string | null,
    light?: boolean
}

const TextProperty: React.FC<TextPropertyProps> = ({ caption, value, light = false }) => {
    return (
        <Box sx={classes.root}>
            <FieldCaption caption={caption} />
            { (light || value === null)
                ? <LightTypography className='root' variant='body2'>{value === null ? '[empty]' : value}</LightTypography>
                : <Typography variant="body2">{value}</Typography>
            }
        </Box>
    );
}

type CheckboxFieldProps = {
    displayName: string,
    value: boolean,
    id: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void | undefined,
    disabled?: boolean
};

const CheckboxField: React.FC<CheckboxFieldProps> = ({ displayName, value, id, onChange, disabled }) => {
    return (
        <FormControl disabled={disabled}>
            <FormControlLabel
                control={
                    <Checkbox
                        name={id}
                        color='secondary'
                        checked={value}
                        onChange={onChange}
                    />
                }
                label={displayName}
            />
        </FormControl>
    )
};

type NumberFieldProps = {
    displayName: string,
    value: number,
    id: string,
    onChange: (value: number | '' | null) => void,
    disabled: boolean,
    notSetLabel: string
};

const NumberField: React.FC<NumberFieldProps> = ({ displayName, value, id, onChange, disabled, notSetLabel }) => {
    return (
        <Box>
            <Typography variant='caption' display='block'>{displayName}</Typography>
            { typeof(value) !== 'undefined' && value !== null
                ?   <>
                        <FormControl disabled={disabled} style={{ marginRight:8, marginBottom:2 }}>
                            <Input
                                id={id}
                                type='number'
                                value={value}
                                onChange={e => onChange(e.target.value === '' ? '' : parseInt(e.target.value))}
                                endAdornment={<InputAdornment position="end">days</InputAdornment>}
                                inputProps={{
                                    min:0
                                }}
                            />
                        </FormControl>
                        {!disabled && <DciLink onClick={e => { e.preventDefault(); onChange(null); }}>Remove Value</DciLink>}
                    </>
                :   <>
                        <Typography style={{ display:'inline', marginRight:8, lineHeight:'25px' }} paragraph color="textSecondary" variant="body2">{notSetLabel}</Typography>
                        {!disabled && <DciLink onClick={e => { e.preventDefault(); onChange(0); }}>Set Value</DciLink>}
                    </>
            }
        </Box>
    );
};

const ControlButton = styled(Button)`
    margin:25px 5px 25px 0px;
`;

const FormCardContent = styled(CardContent)`
    & .MuiFormControl-root {
        display: block;
        & .MuiInputBase-root {
            min-width:500px;
            margin-bottom: 16px;
        }
    }
    & > .MuiAutocomplete-root {
        width: 500px;
    }
`;

export { CheckboxField, ControlButton, FieldCaption, FormCardContent, NumberField, PageTitle, TextProperty, LightTypography };