import { WNActiveItemsChart } from './Items/WNActiveItemsChart';
import { WNChartClickThrough } from './Items/WNChartClickThrough';
import { WNCreateUser } from './Items/WNCreateUser';
import { WNCustomChartColours } from './Items/WNCustomChartColours';
import { WNDarkMode } from './Items/WNDarkMode';
import { WNDisableWorkQueue } from './Items/WNDisableWorkQueue';
import { WNFilterByTag } from './Items/WNFilterByTag';
import { WNFixesJune23 } from './Items/WNFixesJune23';
import { WNImprovedFilters } from './Items/WNImprovedFilters';
import { WNMissedAChange } from './Items/WNMissedAChange';
import { WNMultipleFilters } from './Items/WNMultipleFilters';
import { WNNewFiltersJune23 } from './Items/WNNewFiltersJune23';
import { WNNewTag } from './Items/WNNewTag';
import { WNNovember24OtherChanges } from './Items/WNNovember24OtherChanges';
import { WNOctober2024 } from './Items/WNOctober2024';
import { WNRuleStatistics } from './Items/WNRuleStatistics';
import { WNRuleViewLayout } from './Items/WNRuleViewLayout';
import { WNShareableUrls } from './Items/WNShareableUrls';
import { WNTagRule } from './Items/WNTagRule';
import { WNTagsScreen } from './Items/WNTagsScreen';
import { WNUserActivity } from './Items/WNUserActivity';
import { WNViewItemsForTeam } from './Items/WNViewItemsForTeam';
import { WNWorkQueueView } from './Items/WNWorkQueueView';

type WhatsNewDatum = {
    item: React.ReactNode,
    date: Date,
    index?: number
}

const WhatsNewData: WhatsNewDatum[] = [
    {
        item: <WNDisableWorkQueue />,
        date: new Date(Date.parse('2022-10-31')),
        index: 1
    },
    {
        item: <WNWorkQueueView />,
        date: new Date(Date.parse('2022-10-31')),
        index: 2
    },
    {
        item: <WNCreateUser />,
        date: new Date(Date.parse('2022-11-06'))
    },
    {
        item: <WNViewItemsForTeam />,
        date: new Date(Date.parse('2022-11-08'))
    },
    {
        item: <WNImprovedFilters />,
        date: new Date(Date.parse('2023-03-08')),
        index: 1
    },
    {
        item: <WNMultipleFilters />,
        date: new Date(Date.parse('2023-03-08')),
        index: 2
    },
    {
        item: <WNDarkMode />,
        date: new Date(Date.parse('2023-03-08')),
        index: 3
    },
    {
        item: <WNNewFiltersJune23 />,
        date: new Date(Date.parse('2023-06-15')),
        index: 1
    },
    {
        item: <WNShareableUrls />,
        date: new Date(Date.parse('2023-06-15')),
        index: 2
    },
    {
        item: <WNFixesJune23 />,
        date: new Date(Date.parse('2023-06-15')),
        index: 3
    },
    {
        item: <WNActiveItemsChart />,
        date: new Date(Date.parse('2023-11-21'))
    },
    {
        item: <WNUserActivity />,
        date: new Date(Date.parse('2024-04-22'))
    },
    {
        item: <WNOctober2024 />,
        date: new Date(Date.parse('2024-10-20'))
    },
    {
        item: <WNCustomChartColours />,
        date: new Date(Date.parse('2024-11-11'))
    },
    {
        item: <WNRuleViewLayout />,
        date: new Date(Date.parse('2024-11-11'))
    },
    {
        item: <WNRuleStatistics />,
        date: new Date(Date.parse('2024-11-11'))
    },
    {
        item: <WNChartClickThrough />,
        date: new Date(Date.parse('2024-11-11'))
    },
    {
        item: <WNNovember24OtherChanges />,
        date: new Date(Date.parse('2024-11-11'))
    },
    {
        item: <WNMissedAChange />,
        date: new Date(Date.parse('2025-02-10'))
    },
    {
        item: <WNTagsScreen />,
        date: new Date(Date.parse('2025-02-10'))
    },
    {
        item: <WNNewTag />,
        date: new Date(Date.parse('2025-02-10'))
    },
    {
        item: <WNTagRule />,
        date: new Date(Date.parse('2025-02-10'))
    },
    {
        item: <WNFilterByTag />,
        date: new Date(Date.parse('2025-02-10'))
    }
];

const whatsNewDataSortFunction = (a: WhatsNewDatum, b: WhatsNewDatum) => {
    const timeDiff = a.date.getTime() - b.date.getTime();
    if (timeDiff !== 0) {
        return timeDiff;
    }

    return (a.index ?? 0) - (b.index ?? 0);
}

export { WhatsNewData, whatsNewDataSortFunction }