import { useAuth0 } from "@auth0/auth0-react";
import { postDciApiNoThrow } from "../../utils/callDciApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const queryKey = 'master-rules';

const fetchMasterRules = async (token: string) => {
    const response = await postDciApiNoThrow('{masterRules{nodes{masterRuleId,description,shortName}}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.masterRules.nodes as MasterRule[];
    }
}

const useMasterRules = () => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery({
        queryKey: [queryKey],
        queryFn: async () => fetchMasterRules(await getAccessTokenSilently())
    })
}

const useInvalidateMasterRules = () => {
    const queryClient = useQueryClient();
    return () => queryClient.invalidateQueries({ queryKey: [queryKey] });
}

interface MasterRule {
    masterRuleId: number
    description: string
    shortName: string | null
}

export { useMasterRules, useInvalidateMasterRules }