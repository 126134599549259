import { 
    Typography
} from "@mui/material";
import { FilterControlProps } from "./types";
import { useAuth0 } from "@auth0/auth0-react";
import { getDciApiResponse } from "../../utils/callDciApi";
import { useQuery } from "@tanstack/react-query";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { WorkItemStatusApi } from "../Api/WorkItemStatusApi";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";

interface WorkItemStatus {
    workItemStatusId: number,
    description: string,
    currentStatus: boolean
}

const fetchWorkItemStatuses = async (token: string): Promise<WorkItemStatus[]> => {
    const response = await getDciApiResponse('{workItemStatuses{workItemStatusId,description,currentStatus}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.workItemStatuses as WorkItemStatus[];
    }
    // TODO: Error handling
}

const useWorkItemStatuses = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ["work-item-statuses"],
        queryFn: async () => fetchWorkItemStatuses(await getAccessTokenSilently())
    })
}

const WorkItemStatusFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const { data, isFetching } = useWorkItemStatuses();
    const selectedValues = value as WorkItemStatus[];

    const getTextValue = (value: WorkItemStatus[]) => value.length === 1 
        ? value[0].description
        : `${value.length} selected`

    const setAutoCompleteValue = (newValue: WorkItemStatus[]) => {
        setValue(newValue, newValue.length > 0, false);
    }
    
    if (editMode) {
        return <FilterAutocomplete
            data={data}
            getOptionLabel={o => o.description}
            getTextValue={getTextValue}
            isLoading={isFetching}
            isOptionEqualToValue={(o, v) => o.workItemStatusId === v.workItemStatusId}
            selectedOptions={selectedValues}
            setValue={setAutoCompleteValue}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {selectedValues.length === 1 
                ? ['=', selectedValues[0].description].join(' ')
                : `= ${selectedValues.length} selected`}
        </Typography>
    )
}

const WorkItemStatusFilterDefaultValue = [] as WorkItemStatus[];

const converter: FilterToParameterConverter = (name: string, value: WorkItemStatus[]) => ({
    name: name,
    type: PARAMETER_TYPE.NUMBER,
    value: value.map(wis => wis.workItemStatusId)
});

registerParameterConverter(FILTER_TYPE.WORK_ITEM_STATUS, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: WorkItemStatus[]) => f.map(wis => wis.workItemStatusId).join(),
    deserialize: async s => {
        const users = await WorkItemStatusApi.get();

        return s
            .split(',')
            .map(v => parseInt(v))
            .filter(v => !isNaN(v))
            .map(v => users.find(wis => wis.workItemStatusId === v))
            .filter(v => v !== undefined);
    }
}

registerFilterUrlSerializer(FILTER_TYPE.WORK_ITEM_STATUS, serializer);

export {
    WorkItemStatusFilter,
    WorkItemStatusFilterDefaultValue
}