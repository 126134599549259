import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useMasterRules } from "../Api/MasterRules";

interface AddDialogProps {
    isOpen: boolean,
    close: (saved: boolean) => void
    onSave: (id: number, description: string, shortName: string | null) => Promise<boolean>
}

const CreateMasterRuleDialog = ({ isOpen, close, onSave }: AddDialogProps) => {
    const { data, isFetching } = useMasterRules();
    const [ saving, setSaving ] = useState(false);
    const [ description, setDescription ] = useState('');
    const [ shortName, setShortName ] = useState('');
    const [ id, setId ] = useState('');
    const [ idErrorHelper, setIdErrorHelper ] = useState('');
    const [ descriptionErrorHelper, setDescriptionErrorHelper ] = useState('');
    const [ shortNameErrorHelper, setShortNameErrorHelper ] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const saveOnClick = async () => {
        setSaving(true);
        try {
            if (await onSave(parseInt(id.trim()), description.trim(), shortName.trim() === '' ? null : shortName.trim())) {
                close(true);
                clearFields();
            }
        } finally {
            setSaving(false);
        }
    }

    const clearFields = () => {
        setId('');
        setIdErrorHelper('');
        setDescription('');
        setDescriptionErrorHelper('');
        setShortName('');
        setShortNameErrorHelper('');
    }

    const cancelOnClick = () => {
        close(false);
        clearFields();
    }

    const setIdValue = (value: string) => {
        setId(value);
        if (/^[0-9]+$/.test(value.trim())) {
            const newId = parseInt(value);
            if (data!.some(mr => mr.masterRuleId === newId)) {
                setIdErrorHelper('This ID is already in use')                
            } else {
                setIdErrorHelper('')                
            }
        } else {
            setIdErrorHelper('Value must be postive whole number')
        }
    }

    const setDescriptionValue = (value: string) => {
        setDescription(value);
        if (data!.some(mr => mr.description === value.trim())) {
            setDescriptionErrorHelper('This description is already in use')                
        } else {
            setDescriptionErrorHelper('')                
        }
    }

    const setShortNameValue = (value: string) => {
        setShortName(value);
        if (value !== '' && data!.some(mr => mr.shortName === value.trim())) {
            setShortNameErrorHelper('This short name is already in use')                
        } else {
            setShortNameErrorHelper('')                
        }
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>Create Master Rule</DialogTitle>
            <DialogContent>
                { isFetching
                    ? <p>Loading...</p>
                    : <>
                        <TextField
                            autoFocus
                            variant='standard'
                            margin='dense'
                            id='name'
                            label='Numeric ID'
                            type='text'
                            fullWidth
                            error={idErrorHelper !== ''}
                            helperText={idErrorHelper}
                            value={id}
                            onChange={(event) => setIdValue(event.target.value)}
                        />
                        <TextField
                            variant='standard'
                            margin='dense'
                            id='name'
                            label='Choose a description for the new Master Rule'
                            type='text'
                            fullWidth
                            error={descriptionErrorHelper !== ''}
                            helperText={descriptionErrorHelper}
                            value={description}
                            onChange={(event) => setDescriptionValue(event.target.value)}
                        />
                        <TextField
                            variant='standard'
                            margin='dense'
                            id='name'
                            label='Short Name'
                            type='text'
                            fullWidth
                            error={shortNameErrorHelper !== ''}
                            helperText={shortNameErrorHelper}
                            value={shortName}
                            onChange={(event) => setShortNameValue(event.target.value)}
                        />
                    </>
                }
                
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelOnClick}>Cancel</Button>
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button
                        disabled={description === '' || descriptionErrorHelper !== '' || id === '' || idErrorHelper !== '' || shortNameErrorHelper !== '' || saving}
                        onClick={saveOnClick}
                    >
                        Create
                    </Button>
                        { saving && (
                            <CircularProgress
                                size={24}
                                sx={theme => ({
                                    color: theme.palette.secondary.main,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                })}
                            />
                        )}
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export { CreateMasterRuleDialog }