import {
    MenuList,
    MenuItem,
    Paper,
    Popover,
    ToggleButtonGroup,
    ToggleButton,
    useTheme,
    ListItemIcon,
    ListItemText,
    Divider
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { settings } from '../settings';
import { useContext } from 'react';
import { DarkMode, LightMode, Logout, TipsAndUpdates } from '@mui/icons-material';
import { DciThemeContext } from './Layout/DciTheme';
import { UserClient } from './Api/CurrentUser';
import { LocalClient } from './Api/LocalClient';
import { useStore } from 'zustand';
import { appGlobalStore } from '../AppGlobalStore';
import { WhatsNewData, whatsNewDataSortFunction } from './WhatsNew/WhatsNewData';

type LoggedInUserMenuProps = {
    anchorEl?: Element,
    handleClose: () => void,
    isOpen: boolean
}

export const LoggedInUserMenu = ({ anchorEl, handleClose, isOpen }: LoggedInUserMenuProps) => {
    const { logout } = useAuth0();
    const dciThemeContext = useContext(DciThemeContext);

    const updateUserSettings = useStore(appGlobalStore, s => s.updateUserSettings);
    const setWhatsNewDialogIsOpen = useStore(appGlobalStore, s => s.setWhatsNewDialogIsOpen);
    const setWhatsNewMustSeeAll = useStore(appGlobalStore, s => s.setWhatsNewMustSeeAll);

    const setWhatsNewItems = useStore(appGlobalStore, s => s.setWhatsNewItems);

    const theme = useTheme();

    const setColorMode = async (value: any) => {
        if (value !== null) {
            dciThemeContext.setColorMode(value);
            updateUserSettings({ colorMode: value });
            LocalClient.set('User.Settings.ColorMode', value);
        }
    }

    const openWhatsNewDialog = () => {
        const newItems = WhatsNewData
            .sort(whatsNewDataSortFunction)
            .map(m => m.item);

        setWhatsNewItems(newItems);
        setWhatsNewDialogIsOpen(true);
        setWhatsNewMustSeeAll(false);
        handleClose();
    }

    return (
        <Popover
            id='loggedInUserMenu'
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Paper sx={{ width: 250, maxWidth: '100%' }}>
                <MenuList>
                    <MenuItem onClick={openWhatsNewDialog}>
                        <ListItemIcon>
                            <TipsAndUpdates />
                        </ListItemIcon>
                        <ListItemText>Change History</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => logout({ returnTo:settings.LOGOUT_REDIRECT})}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </MenuList>
                <Divider />
                <ToggleButtonGroup
                    style={{ width:'100%', padding:'10px' }}
                    value={theme.palette.mode}
                    exclusive
                    onChange={(_, value) => setColorMode(value)}
                    aria-label="theme mode"
                >
                    <ToggleButton style={{ width:'50%' }} value="light" aria-label="light mode">
                        <LightMode />
                        Light
                    </ToggleButton>
                    <ToggleButton style={{ width:'50%' }} value="dark" aria-label="dark mode">
                        <DarkMode />
                        Dark
                    </ToggleButton>
                </ToggleButtonGroup>
            </Paper>
      </Popover>
    );
};