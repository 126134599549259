import dciPaths from "../../utils/dciPaths";
import { TableColumn } from "../Filtering/types";
import OrgContainer from "../Organisation/OrgContainer";
import { StandardListView } from "../StandardListView"

type CoreTag = {
    tagId: number,
    name: string,
    description: string | null,
    colour: string | null,
    RuleCount: number
}

const columns: TableColumn[] = [
    {
        displayName: 'Name',
        selector: 'name',
        style: { width:'300px' }
    },
    {
        displayName: 'Rule Count',
        selector: 'ruleCount',
        style: { width:'100px' },
        justify: 'center'
    },
    {
        displayName: 'Description',
        selector: 'description',
    }
];

const CoreTags = () => {
    return <OrgContainer>
        <StandardListView<CoreTag>
           columns={columns}
           graphQLQueryColumns='{tagId,name,description,ruleCount}'
           graphQLQueryName='coreTags'
           idFromRow={(row: CoreTag) => row.tagId}
           title='Core Tags'
           uniqueSortColumn='tagId'
           buildLink={r => dciPaths.coreTag.buildLink(r.tagId)}
           initialState={{
               paged: true,
               sortOrder: [{ column:'name' }]
           }}
       />
    </OrgContainer>
}

export { CoreTags }