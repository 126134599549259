import { useAuth0 } from "@auth0/auth0-react";
import { 
    Typography
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getDciApiResponse } from "../../utils/callDciApi";
import { FilterControlProps } from "./types";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { FilterNode } from "./TableFilter";
import authClient from "../Auth/auth-client";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";

type Rule = {
    reportTestId: number,
    ruleDescription: string
}

const fetchRules = async (token: string): Promise<Rule[]> => {
    const response = await getDciApiResponse('{allRuleSummaries(order:{reportTestId:ASC}){reportTestId,ruleDescription}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.allRuleSummaries as Rule[];
    }
    // TODO: Error handling
}

const useRules = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ["rules-dropdown"],
        queryFn: async () => fetchRules(await getAccessTokenSilently())
    })
}

const RuleFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const { data, isFetching } = useRules();

    const selectedRules = value as Rule[];

    const getOptionLabel = (rule: Rule) => `${rule.reportTestId}: ${rule.ruleDescription}`;

    const getTextValue = (value: Rule[]) => value.length === 1 
        ? getOptionLabel(selectedRules[0])
        : `${selectedRules.length} selected`

    const setAutoCompleteValue = (newValue: Rule[]) => {
        setValue(newValue, newValue.length > 0, false);
    }

    if (editMode) {
        return <FilterAutocomplete
            data={data}
            isLoading={isFetching}
            getOptionLabel={getOptionLabel}
            getTextValue={getTextValue}
            isOptionEqualToValue={(o, v) => o.reportTestId === v.reportTestId}
            selectedOptions={selectedRules}
            setValue={setAutoCompleteValue}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {selectedRules.length === 1 
                ? ['=', getOptionLabel(selectedRules[0])].join(' ')
                : `= ${selectedRules.length} selected`}
        </Typography>
    )
}

const RuleFilterDefaultValue = [] as Rule[];

const converter: FilterToParameterConverter = (name: string, value: Rule[]) => ({
    name: name,
    type: PARAMETER_TYPE.NUMBER,
    value: value.map(r => r.reportTestId)
});

registerParameterConverter(FILTER_TYPE.RULE, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: Rule[]) => f.map(r => r.reportTestId).join(),
    deserialize: async s => {
        const token = await authClient.getTokenSilently();
        const rules = await fetchRules(token);

        return s
            .split(',')
            .map(v => parseInt(v))
            .filter(v => !isNaN(v))
            .map(v => rules.find(r => r.reportTestId === v))
            .filter(v => v !== undefined)
            .map(v => ({
                reportTestId: v!.reportTestId,
                ruleDescription: v!.ruleDescription
            }) as Rule)
    }
}

registerFilterUrlSerializer(FILTER_TYPE.RULE, serializer);

export {
    RuleFilter,
    RuleFilterDefaultValue
}