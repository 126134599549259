import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSearchParamsState, useSearchParamsStateFlag } from "../../utils/useSearchParamsState";
import { Box, Button, MenuItem, Paper, Select, Stack, Tab, TextField, Typography, useTheme } from "@mui/material";
import { AuditList } from "../AuditList";
import { Tag, useTag } from "../Api/ReactQuery";
import OrgContainer from "./OrgContainer";
import userHasPermission from "../../utils/userHasPermission";
import { useStore } from "zustand";
import { appGlobalStore } from "../../AppGlobalStore";
import { permissions, tagDefaultColour } from "../../utils/dciConstants";
import { Delete, Edit, Save } from "@mui/icons-material";
import { ControlButton, FieldCaption } from "../DciControls";
import { useState } from "react";
import { ColourPicker, getRandomHexColour } from "../ColourPicker";
import { useAuth0 } from "@auth0/auth0-react";
import { postDciApi, postDciApiNoThrow } from "../../utils/callDciApi";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { serializeString } from "../../GraphQLShared";
import dciPaths from "../../utils/dciPaths";
import { useNavigate } from "react-router-dom";

interface TagViewProps {
    id: number
}

const TagView = ({ id }: TagViewProps) => {
    const [ tabIndex, setTabIndex ] = useSearchParamsState('tab', 'tag');

    if (['tag', 'statistics', 'audit'].indexOf(tabIndex) === -1) {
        setTabIndex('rule');
    }

    return (
        <OrgContainer>
            <TabContext value={tabIndex}>
                <Stack component={Paper} sx={{ height:'100%' }}>
                    <Box>
                        <TabList onChange={(_, v) => setTabIndex(v)}>
                            <Tab label='Tag' value='tag' />
                            <Tab label='Audit' value='audit' />
                        </TabList>
                    </Box>
                    <TabPanel sx={{ flexGrow:1, overflow:'hidden' }} value='tag'>
                        <TagTab id={id} />
                    </TabPanel>
                    <TabPanel sx={{ padding:'5px 0px 0px', flexGrow:1, overflow:'hidden' }} value='audit'>
                        <AuditList type='Tag' entityKey={[{ fieldName:'TagId', fieldValue:id }]} addOrgParam={false} />
                    </TabPanel>
                </Stack>
            </TabContext>
        </OrgContainer>
    )
}

const TagTab = ({ id }: TagViewProps) => {
    const queryClient = useQueryClient();
    const navigate =  useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();
    const { isFetching, data } = useTag(id);
    const [ editMode, setEditMode ] = useSearchParamsStateFlag('editMode', false);
    const [ editObject, setEditObject ] = useState<Tag | null>(null);

    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_TAGS);
    const hasDeletePermission = userHasPermission(currentUser, permissions.DELETE_TAGS);
 
    if (isFetching) {
        return <Typography>Loading...</Typography>
    }

    if (data === undefined || data === null) {
        return <Typography>Tag not found.</Typography>
    }

    const beginEdit = () => {
        setEditObject({ ...data });
        setEditMode(true);
    }

    const changesMade = () => {
        if (!editObject) {
            return false;
        }
    
        return editObject.colour?.toLowerCase() !== data.colour?.toLowerCase()
        || editObject.description !== data.description
        || editObject.name !== data.name;
    };

    const saveChanges = async () => {
        if (editObject === null) {
            return;
        }

        const token = await getAccessTokenSilently();
        postDciApiNoThrow(`mutation{editTag(tagId:${editObject.tagId},name:"${editObject.name.trim()}",description:${serializeString(editObject.description)},colour:"${editObject.colour}"){tagId}}`, token)
        .then(body => {
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setEditMode(false);
                queryClient.invalidateQueries({ queryKey: ['tag', data.tagId] })
                enqueueSnackbar('Saved', { variant:'success' });
            }
        })
        .catch(error => {
            console.error(`[TagView] editTag: ${error}`);
        });
    }

    const deleteTag = async () => {
        if (window.confirm(`Delete tag named '${data.name}'?`) === true) {
            const token = await getAccessTokenSilently();
            postDciApiNoThrow(`mutation{deleteTag(tagId:${id})}`, token)
            .then(body => {
                if (!body.errors) {
                    navigate(dciPaths.tags.buildLink());
                    enqueueSnackbar(`Deleted tag "${data.name}"`, { variant:'success' });
                } else {
                    enqueueSnackbar(body.errors[0].message, { variant:'error' });
                }
            })
            .catch(error => {
                console.error(`[TagView] editTag: ${error}`);
            });
        }
    }

    return <>
        <Stack style={{ height:'100%', width:'100%' }}>
            <Box style={{ flexGrow:1, overflowY:'auto' }}>
                <Box style={{ float:'right' }}>
                    { hasDeletePermission && !editMode && <Button onClick={deleteTag} startIcon={<Delete />}>Delete</Button> }
                    { hasEditPermission && !editMode && <Button startIcon={<Edit />} onClick={beginEdit}>Edit</Button> }
                </Box>
                { editMode
                    ? <TagEdit tag={editObject!} setTag={factory => setEditObject(existing => factory(existing!))} />
                    : <TagReadOnlyView tag={data} />
                }
            </Box>
            { editMode &&
            <Box>
                <ControlButton style={{ marginBottom:'0px' }} onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                <ControlButton style={{ marginBottom:'0px' }} onClick={() => setEditMode(false)} variant='contained'>Cancel</ControlButton>
            </Box> }
        </Stack>
    </>
}

interface TagEditProps {
    tag: Tag
    setTag: (factory: (existing: Tag) => Tag) => void
}

const TagEdit = ({ tag, setTag }: TagEditProps) => {
    return <Stack gap={2} sx={{ maxWidth:'400px' }}>
        <TextField
            variant='standard'
            label='Name'
            error={tag.name === ''}
            value={tag.name}
            onChange={e => setTag(existing => ({ ...existing, name: e.target.value }))}
        />
        <TextField
            fullWidth
            variant='standard'
            label='Description'
            value={tag.description ?? ''}
            onChange={e => setTag(existing => ({ ...existing, description: e.target.value === '' ? null : e.target.value }))}
        />
        <Box>
            <FieldCaption caption='Colour' />
            <Stack direction='row' style={{ minHeight:'40px' }}>
                <Select
                    variant='standard'
                    style={{ width:'180px' }}
                    size='small'
                    value={tag.colour === null ? 'system' : 'user'}
                    onChange={e => setTag(existing => ({ ...existing, colour: e.target.value === 'system' ? null : getRandomHexColour()}))}
                >
                    <MenuItem value='system'>System Default</MenuItem>
                    <MenuItem value='user'>User-defined</MenuItem>
                </Select>
                { tag.colour === null ? null : <ColourPicker colour={tag.colour} setColour={c => setTag(existing => ({ ...existing, colour: c }))} /> }
            </Stack>

        </Box>
    </Stack>
}

const TagReadOnlyView = ({ tag }: { tag: Tag }) => {
    const theme = useTheme();
    return <Stack direction='row' alignItems='center' gap={2}>
        <Box style={{
            minWidth:'75px',
            height:'75px',
            backgroundColor:tag.colour ?? tagDefaultColour(theme),
            borderRadius:'5px'
        }} />
        <Stack>
            <Typography variant='h3'>{tag.name}</Typography>
            <Typography>{tag.description}</Typography>
        </Stack>
    </Stack>
}

export { TagView }