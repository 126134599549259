import { useState } from 'react';
import { AddBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import { 
    Box,
    Button,
    TableCell, 
    alpha
} from '@mui/material';

import OrgContainer from './OrgContainer';
import { AddRoleDialog } from './AddRoleDialog';
import dciPaths from '../../utils/dciPaths';
import { TableStoreContext, createTableStore, useTableState } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';
import { CreateTagDialog } from '../CreateTagDialog';
import { useStore } from 'zustand';
import userHasPermission from '../../utils/userHasPermission';
import { appGlobalStore } from '../../AppGlobalStore';
import { permissions } from '../../utils/dciConstants';

type Tag = {
    tagId: number,
    name: string,
    description: string | null,
    colour: string | null,
    RuleCount: number
}

const columns: TableColumn[] = [
    {
        displayName: 'Name',
        selector: 'name',
        style: { width:'300px' }
    },
    {
        displayName: 'Colour',
        selector: 'colour',
        style: { width:'40px' },
        render: (row: Tag) => <TableCell style={{ textAlign: 'center' }} padding={'checkbox'}>{(row.colour ? <Box sx={{ marginLeft:'23px', width:'40px', height:'12px', backgroundColor:row.colour }}></Box> : <Box sx={theme => ({ color:alpha(theme.palette.text.primary, 0.2) })} component='span'>Default</Box>)}</TableCell>
    },
    {
        displayName: 'Rule Count',
        selector: 'ruleCount',
        style: { width:'100px' },
        justify: 'center'
    },
    // TODO: Needed to put the following "blank" column because otherwise the columns were formatting badly. Needs a central fix
    {
        displayName: 'Description',
        selector: 'description',
    }
];

const createStore = () => createTableStore({
        graphQLQueryName: 'tags',
        graphQLQueryColumns: '{tagId,name,colour,description,ruleCount}',
        uniqueSortColumn: 'tagId',
        idFromRow: (row: Tag) => row.tagId
    },
    {
        paged: true,
        sortOrder: [{ column:'name' }]
    });

const Controls = () => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const canCreateTags = userHasPermission(currentUser, permissions.CREATE_TAGS);
    const [ createTagDialogIsOpen, setCreateTagDialogIsOpen ] = useState(false);
    const updateData = useTableState(s => s.updateRowData);

    return !canCreateTags ? null : <>
        <Button
            sx={theme => ({ 
                color:theme.palette.text.primary,
                borderColor:theme.palette.divider,
                '&:hover': {
                    borderColor: theme.palette.action.disabledBackground,
                    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                }
            })}
            style={{ margin:'0px 4px' }}
            endIcon={<AddBox />}
            variant='outlined'
            onClick={() => setCreateTagDialogIsOpen(true)}
            aria-label='add tag'>
                New
        </Button>
        <CreateTagDialog
            isOpen={createTagDialogIsOpen}
            close={t => {
                setCreateTagDialogIsOpen(false);
                if (t) {
                    updateData(false);
                }
            }}
        />
    </>;
}

const Tags = () => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore())

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Tags'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                    toolbarButtons={<Controls />}
                >
                    <Table
                        uniqueSortColumn='tagId'
                        columns={columns}
                        idFromValue={(row: Tag) => row.tagId}
                        onRowClick={(row: Tag) => navigate(dciPaths.tag.buildLink(row.tagId))}
                        selectable
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    )
};

export { Tags }