import React, { useEffect, useLayoutEffect, useState } from 'react';

import {
    AppBar,
    Button,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
    Box,
    useTheme,
    Hidden,
    SxProps,
    Theme
} from '@mui/material';

import {
    AccountCircle,
    Menu,
    Notifications
} from '@mui/icons-material';

import 'typeface-roboto';
import { Menu as MenuLayout } from './MenuLayout';
import { DciMenu } from '../DciMenu';
import { LoggedInUserMenu } from '../LoggedInUserMenu';
import HelpButton from './HelpButton';
import { WhatsNewData, whatsNewDataSortFunction } from '../WhatsNew/WhatsNewData';
import { WhatsNewDialog } from '../WhatsNew/WhatsNewDialog';
import { AllRoutes } from './AllRoutes';
import { DciBreadcrumb, getBreadcrumbs, SegmentMap } from './DciBreadcrumb';
import { useLocation } from 'react-router-dom';
import { DciLogo } from './DciLogo';
import { SplashScreenLoader } from './SplashScreenLoader';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

const drawerWidth = 240;

const useStyles = () => {
    const theme = useTheme();
    
    return {
        root: {
            display: 'flex',
            minHeight: 0,
            height:'100%'
            //minHeight: '100vh' // Removing while trying the next flex layout to allow nested overflow scrolling content
        } as SxProps<Theme>,
        drawer: {
            [theme.breakpoints.up('lg')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
            '& .MuiDrawer-paper': {
                width:drawerWidth,
                overflowX:'hidden'
            }
        } as SxProps<Theme>,
        appBar: {
            [theme.breakpoints.up('lg')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        } as SxProps<Theme>,
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        } as SxProps<Theme>,
        
        notificationButton: {
            marginRight: theme.spacing(2)
        } as SxProps<Theme>,
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column'
        } as SxProps<Theme>,
        nested: {
            paddingLeft: theme.spacing(4),
        } as SxProps<Theme>,
        title: {
            flexGrow: 1,
        } as SxProps<Theme>,
    }
}

const DrawerContent = () => {
    return (
        <div>
        <div style={{ padding:'25px' }}>
        <DciLogo />
        </div>
        
        <Divider />
        
        <DciMenu items={MenuLayout} nestingLevel={0} />
        </div>
    )
}
    
const StaticDataLoaderChildren: React.FC = () => {
    const classes = useStyles();
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const whatsNewDialogIsOpen = useStore(appGlobalStore, s => s.whatsNewDialogIsOpen);
    const setWhatsNewDialogIsOpen = useStore(appGlobalStore, s => s.setWhatsNewDialogIsOpen);
    const whatsNewItems = useStore(appGlobalStore, s => s.whatsNewItems);
    const setWhatsNewItems = useStore(appGlobalStore, s => s.setWhatsNewItems);
    const whatsNewMustSeeAll = useStore(appGlobalStore, s => s.whatsNewMustSeeAll);
    const setWhatsNewMustSeeAll = useStore(appGlobalStore, s => s.setWhatsNewMustSeeAll);
    const [ userMenuIsOpen, setUserMenuIsOpen ] = useState(false);
    const [ userMenuAnchorEl, setUserMenuAnchorEl ] = useState<Element | undefined>();
    const [ drawerOpen, setDrawerOpen ] = useState(false);
    
    useEffect(() => {
        const newItems = WhatsNewData
            .filter(f => f.date > currentUser.userSettings.lastWhatsNewSeen)
            .sort(whatsNewDataSortFunction)
            .map(m => m.item);

        setWhatsNewItems(newItems);

        if (newItems.length > 0) {
            setWhatsNewDialogIsOpen(true);
            setWhatsNewMustSeeAll(true);
        }
    }, [])

    const userMenuHandleOpen = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setUserMenuAnchorEl(event.target as Element);
        setUserMenuIsOpen(true);
    }
    
    const userMenuHandleClose = () => {
        setUserMenuAnchorEl(undefined);
        setUserMenuIsOpen(false);
    };
    
    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };
    
    const container = window !== undefined ? () => window.document.body : undefined;
    
    const location = useLocation();
    const [ breadcrumbs, setBreadcrumbs ] = useState<SegmentMap[]>([]);
    
    useLayoutEffect(() => {
        setBreadcrumbs(getBreadcrumbs(location.pathname))
    }, [ location.pathname ]);
    
    return (
        // <Stack direction='column' style={{ height:'100%' }}>
        <Box sx={classes.root} id='shouldberow'>
            <CssBaseline />
            <WhatsNewDialog
                isOpen={whatsNewDialogIsOpen} close={() => setWhatsNewDialogIsOpen(false)}
                items={whatsNewItems}
                mustSeeAll={whatsNewMustSeeAll}
            />
            <Box displayPrint='none'>
                <AppBar position="fixed" sx={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            edge="start"
                            sx={classes.menuButton}
                        >
                            <Menu />
                        </IconButton>
                        <Typography variant="h6" sx={classes.title} noWrap>
                            {currentUser.organisationName}
                        </Typography>
                        <HelpButton />
                        <IconButton color="inherit" aria-label="notifications">
                            <Notifications />
                        </IconButton>
                        <Button color="inherit" endIcon={<AccountCircle />} onClick={userMenuHandleOpen}>
                            <Hidden smDown>{currentUser.firstName} {currentUser.surname}</Hidden>
                        </Button>
                        <LoggedInUserMenu anchorEl={userMenuAnchorEl} handleClose={userMenuHandleClose} isOpen={userMenuIsOpen} />
                    </Toolbar>
                </AppBar>
            </Box>
            <nav>
                <Hidden xlUp implementation='css'>
                    <Drawer
                        container={container}
                        variant='temporary'
                        open={drawerOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <DrawerContent />
                    </Drawer>
                </Hidden>
                <Hidden lgDown implementation='css'>
                    <Drawer
                        sx={classes.drawer}
                        variant='permanent'
                        open
                    >
                        <DrawerContent />
                    </Drawer>
                </Hidden>
            </nav>
        
        {/* Make the following 3 child elements flex so that the last will always have the max element of the screen */}
        <Box component='main' sx={classes.content}>
            <Box sx={classes.toolbar} />
                <Box displayPrint='none'>
                    <DciBreadcrumb breadcrumbs={breadcrumbs} />
                </Box>
                <Box style={{ flexGrow:1, minHeight:0 }}>
                    <AllRoutes setBreadcrumbs={setBreadcrumbs} />
                </Box>
            </Box>
        </Box>
        // </Stack>
    );
};
        
function ProtectedApp() {
    return (
        <SplashScreenLoader>
            <StaticDataLoaderChildren />
        </SplashScreenLoader>
    );
}
            
export default ProtectedApp;