import { alpha, Button, TableCell } from "@mui/material";
import { TableColumn } from "../Filtering/types";
import OrgContainer from "../Organisation/OrgContainer";
import { StandardListView } from "../StandardListView"
import { AddBox, Check } from "@mui/icons-material";
import { useState } from "react";
import { CreateMasterRuleDialog } from "./CreateMasterRuleDialog";
import { useAuth0 } from "@auth0/auth0-react";
import { postDciApiNoThrow } from "../../utils/callDciApi";
import { serializeString } from "../../GraphQLShared";
import { useSnackbar } from "notistack";
import { useInvalidateMasterRules } from "../Api/MasterRules";
import { useTableState } from "../Table/Stores/TableStore";

type MasterRule = {
    masterRuleId: number,
    description: string,
    shortName: string | null,
    pulseReportTestIds: number[],
    imixReportTestIds: number[],
    xplanReportTestIds: number[],
}

const columns: TableColumn[] = [
    {
        displayName: 'ID',
        selector: 'masterRuleId',
        style: { width:'100px' }
    },
    {
        displayName: 'Short Name',
        selector: 'shortName',
        style: { width:'200px' },
    },
    {
        displayName: 'Description',
        selector: 'description',
    },
    {
        displayName: 'Pulse',
        selector: 'pulseReportTestIds',
        sortable: false,
        render: (row: MasterRule) => <TableCell key='pulse' style={{ textAlign: 'center', width:'100px' }} padding={'checkbox'}>{row.pulseReportTestIds.length > 0 ? <Check /> : null}</TableCell>
    },
    {
        displayName: 'IMiX',
        selector: 'imixReportTestIds',
        sortable: false,
        render: (row: MasterRule) => <TableCell key='imix' style={{ textAlign: 'center', width:'100px' }} padding={'checkbox'}>{row.imixReportTestIds.length > 0 ? <Check /> : null}</TableCell>
    },
    {
        displayName: 'Xplan',
        selector: 'xplanReportTestIds',
        sortable: false,
        render: (row: MasterRule) => <TableCell key='xplan' style={{ textAlign: 'center', width:'100px' }} padding={'checkbox'}>{row.xplanReportTestIds.length > 0 ? <Check /> : null}</TableCell>
    },
];

const Controls = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();
    const [ addDialogIsOpen, setAddDialogIsOpen ] = useState(false);
    const invalidateMasterRules = useInvalidateMasterRules();
    const updateRowData = useTableState(s => s.updateRowData);

    const onSave = async (id: number, description: string, shortName: string | null) => {
        const token = await getAccessTokenSilently();
        const response = await postDciApiNoThrow(`mutation{createMasterRule(masterRule:{masterRuleId:${id},description:${serializeString(description)},shortName:${serializeString(shortName)}}){masterRuleId}}`, token);
        if (response.errors) {
            enqueueSnackbar(response.errors[0].message, { variant:'error' });
            return false;
        }
        
        await invalidateMasterRules();
        enqueueSnackbar('Saved', { variant:'success' });
        return true;
    }

    const close = (shouldRefresh: boolean) => {
        setAddDialogIsOpen(false);
        if (shouldRefresh) {
            updateRowData(false);
        }
    }

    return <>
        <Button
            sx={theme => ({ 
                color:theme.palette.text.primary,
                borderColor:theme.palette.divider,
                '&:hover': {
                    borderColor: theme.palette.action.disabledBackground,
                    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                }
            })}
            style={{ margin:'0px 4px' }}
            endIcon={<AddBox />}
            variant='outlined'
            onClick={() => setAddDialogIsOpen(true)}
            aria-label='new master rule'>
                New
        </Button>
        <CreateMasterRuleDialog
            close={close}
            isOpen={addDialogIsOpen}
            onSave={onSave}
        />
    </>
}


const MasterRules = () => {
    return <OrgContainer>
        <StandardListView<MasterRule>
           columns={columns}
           graphQLQueryColumns='{masterRuleId,description,shortName,pulseReportTestIds,imixReportTestIds,xplanReportTestIds}'
           graphQLQueryName='masterRules'
           idFromRow={(row: MasterRule) => row.masterRuleId}
           title='Master Rules'
           uniqueSortColumn='masterRuleId'
           buildLink={r => ''}
           toolbarButtons={<Controls />}
           initialState={{
               paged: true,
               sortOrder: [{ column:'masterRuleId' }]
           }}
       />
    </OrgContainer>
}

export { MasterRules }