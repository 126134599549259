import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/TagsScreen.png';

const WNTagsScreen = () => {
    return (
        <>
            <Typography variant='h5'>Tags</Typography>
            <Typography variant='body1'>We have introduced the ability to tag rules.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Tags Screen" />
        </>
    )
}

export { WNTagsScreen }