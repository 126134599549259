import { useAuth0 } from "@auth0/auth0-react";
import {
    CheckBox,
    CheckBoxOutlineBlank,
    Flag
} from "@mui/icons-material";
import { 
    AutocompleteRenderOptionState,
    Checkbox,
    Typography
} from "@mui/material";
import { FilterTypography } from '../Table/Shared';
import { FilterControlProps } from "./types";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { RegulatoryImpactApi } from "../Api/RegulatoryImpactApi";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";
import { RegulatoryImpact, useRegulatoryImpacts } from "../Api/ReactQuery";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const RegulatoryImpactFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const { data, isFetching } = useRegulatoryImpacts();

    const selectedValues = value as RegulatoryImpact[];

    const getTextValue = (value: RegulatoryImpact[]) => value.length === 1 
        ? value[0].description
        : `${value.length} selected`

    const setAutoCompleteValue = (newValue: RegulatoryImpact[]) => {
        setValue(newValue, newValue.length > 0, false);
    }

    const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: RegulatoryImpact, state: AutocompleteRenderOptionState) =>
        <li style={{ padding:'0px 6px' }} {...props}>
            <Checkbox
                size='small'
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={state.selected}
                color='secondary'
            />
            <Flag style= {{ 
                color: option.displayIcon,
                marginBottom: -2,
                marginRight: 5
            }} />
            <FilterTypography>{option.description}</FilterTypography>
        </li>;

    if (editMode) {
        return <FilterAutocomplete
            data={data}
            getOptionLabel={o => o.description}
            getTextValue={getTextValue}
            isLoading={isFetching}
            isOptionEqualToValue={(o, v) => o.regulatoryImpactId === v.regulatoryImpactId}
            selectedOptions={selectedValues}
            setValue={setAutoCompleteValue}
            renderOption={renderOption}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {['=', getTextValue(selectedValues)].join(' ')}
        </Typography>
    )
}

const RegulatoryImpactFilterDefaultValue = [] as RegulatoryImpact[];

const converter: FilterToParameterConverter = (name: string, value: RegulatoryImpact[]) => ({
    name: name,
    type: PARAMETER_TYPE.NUMBER,
    value: value.map(ri => ri.regulatoryImpactId)
});

registerParameterConverter(FILTER_TYPE.REGULATORY_IMPACT, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: RegulatoryImpact[]) => f.map(ri => ri.regulatoryImpactId).join(),
    deserialize: async s => {
        const ris = await RegulatoryImpactApi.get();

        return s
            .split(',')
            .map(v => parseInt(v))
            .filter(v => !isNaN(v))
            .map(v => ris.find(ri => ri.regulatoryImpactId === v))
            .filter(v => v !== undefined)
            .map(v => ({
                description: v!.description,
                displayIcon: v!.displayIcon,
                regulatoryImpactId: v!.regulatoryImpactId,
                regulatoryImpactValue: v!.regulatoryImpactValue
            }) as RegulatoryImpact)
    }
}

registerFilterUrlSerializer(FILTER_TYPE.REGULATORY_IMPACT, serializer);

export {
    RegulatoryImpactFilter,
    RegulatoryImpactFilterDefaultValue
}