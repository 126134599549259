type DciRoute = {
    pattern: string,
    buildLink: (...args: any[]) => string,
    helpLink?: string,
    regex?: RegExp
};

const configuration: DciRoute = {
    pattern: '/configuration',
    buildLink: () => '/configuration',
    helpLink: '/configuration'
};

const settings: DciRoute = {
    pattern: '/configuration/orgconfig',
    buildLink: () => '/configuration/orgconfig',
    helpLink: '/configuration/settings'
};

const orgConstants: DciRoute = {
    pattern: '/configuration/orgconstants',
    buildLink: () => '/configuration/orgconstants',
    helpLink: '/configuration/constants'
};

const orgValueLists: DciRoute = {
    pattern: '/configuration/orgvaluelists',
    buildLink: () => '/configuration/orgvaluelists',
    helpLink: '/configuration/value-lists/'
}

const dashboard: DciRoute = {
    pattern: '/dashboard',
    buildLink: () => '/dashboard',
    helpLink: '/dashboard'
};

const history: DciRoute = {
    pattern: '/history',
    buildLink: () => '/history'
};

const masterRules: DciRoute = {
    pattern: '/masterrules',
    buildLink: () => '/masterrules'
};

const reportingRun: DciRoute = {
    pattern: '/history/reportingrunhistory/:id',
    buildLink: (reportingRunHistoryId: string | number) => `/history/reportingrunhistory/${reportingRunHistoryId}`
}

const user: DciRoute = {
    pattern: '/configuration/user/:id',
    buildLink: (userId: string | number) => `/configuration/user/${userId}`,
    helpLink: '/configuration/users',
    regex: /^\/configuration\/user\/\d+$/
};

const userActivity: DciRoute = {
    pattern: '/useractivity',
    buildLink: () => '/useractivity'
}

const organisation: DciRoute = {
    pattern: '/configuration/organisation',
    buildLink: () => '/configuration/organisation',
    helpLink: '/configuration/organisation'
};

const users: DciRoute = {
    pattern: '/configuration/users',
    buildLink: () => `/configuration/users`,
    helpLink: '/configuration/users',
    regex: /^\/configuration\/user(s|\/\d+)$/
};

const teams: DciRoute = {
    pattern: '/configuration/teams',
    buildLink: () => '/configuration/teams',
    helpLink: '/configuration/teams',
    regex: /^\/configuration\/team(s|\/\d+)$/
};

const team: DciRoute = {
    pattern: '/configuration/team/:id',
    buildLink: (teamId : string | number) => `/configuration/team/${teamId}`,
    helpLink: '/configuration/teams',
    regex: /^\/configuration\/team\/\d+$/
};

const role: DciRoute = {
    pattern: '/configuration/role/:id',
    buildLink: (roleId : string | number) => `/configuration/role/${roleId}`,
    helpLink: '/configuration/roles',
    regex: /^\/configuration\/role\/\d+$/
};

const roles: DciRoute = {
    pattern: '/configuration/roles',
    buildLink: () => '/configuration/roles',
    helpLink: '/configuration/roles',
    regex: /^\/configuration\/role(s|\/\d+)$/
};

const ruleGroup: DciRoute = {
    pattern: '/configuration/rulegroup/:id',
    buildLink: (ruleGroupId : string | number) => `/configuration/rulegroup/${ruleGroupId}`,
    helpLink: '/configuration/rule-groups',
    regex: /^\/configuration\/rulegroup\/\d+$/
};

const ruleGroups: DciRoute = {
    pattern: '/configuration/rulegroups',
    buildLink: () => '/configuration/rulegroups',
    helpLink: '/configuration/rule-groups',
    regex: /^\/configuration\/rulegroup(s|\/\d+)$/
};

const workQueues: DciRoute = {
    pattern: '/configuration/workqueues',
    buildLink: () => '/configuration/workqueues',
    helpLink: '/configuration/work-queues',
    regex: /^\/configuration\/workqueue(s|\/\d+)$/
};

const workQueue: DciRoute = {
    pattern: '/configuration/workqueue/:id',
    buildLink: (id: string | number) => `/configuration/workqueue/${id}`,
    helpLink: '/configuration/work-queues',
    regex: /^\/configuration\/workqueue\/\d+$/
};

const reporting: DciRoute = {
    pattern: '/reporting',
    buildLink: () => '/reporting',
    helpLink: '/reporting'
};

const rule: DciRoute = {
    pattern: '/configuration/rule/:id',
    buildLink: (ruleId : string | number) => `/configuration/rule/${ruleId}`,
    helpLink: '/configuration/rules',
    regex: /^\/configuration\/rule\/\d+$/
};

const rules: DciRoute = {
    pattern: '/configuration/rules',
    buildLink: () => '/configuration/rules',
    helpLink: '/configuration/rules',
    regex: /^\/configuration\/rule(s|\/\d+$)/
};

const tag: DciRoute = {
    pattern: '/configuration/tag/:id',
    buildLink: (tagId : string | number) => `/configuration/tag/${tagId}`,
    regex: /^\/configuration\/tag\/\d+$/
};

const tags: DciRoute = {
    pattern: '/configuration/tags',
    buildLink: () => '/configuration/tags',
    regex: /^\/configuration\/tag(s|\/\d+)$/
};

const audit: DciRoute = {
    pattern: '/audit/:id',
    buildLink: (auditId: string | number) => `/audit/${auditId}`
};

const workItem: DciRoute = {
    pattern: '/workitem/:id',
    buildLink: (workItemId: string | number) => `/workitem/${workItemId}`,
    helpLink: '/work-items'
};

const workItemAttributeMap: DciRoute = {
    pattern: '/configuration/workitemattributemap/:id',
    buildLink: (workItemAttributeMapId: number) => `/configuration/workitemattributemap/${workItemAttributeMapId}`
}

const workItemAttributeMaps: DciRoute = {
    pattern: '/configuration/workitemattributemaps',
    buildLink: () => '/configuration/workitemattributemaps',
    regex: /^\/configuration\/workitemattributemap/i
}

const workItemsDistribution: DciRoute = {
    pattern: '/workitems/distribution',
    buildLink: () => '/workitems/distribution',
    helpLink: '/work-items/distribution'
};

const workItemsQueued: DciRoute = {
    pattern: '/workitems/queued',
    buildLink: () => '/workitems/queued',
    helpLink: '/work-items/administration/queued'
};

const workItemsInProgress: DciRoute = {
    pattern: '/workitems/inprogress',
    buildLink: () => '/workitems/inprogress',
    helpLink: '/work-items/administration/in-progress'
};

const workItemsAwaitingInformation: DciRoute = {
    pattern: '/workitems/waitinginfo',
    buildLink: () => '/workitems/waitinginfo',
    helpLink: '/work-items/administration/awaiting-information'
};

const workItemsOverrideRequest: DciRoute = {
    pattern: '/workitems/overriderequest',
    buildLink: () => '/workitems/overriderequest',
    helpLink: '/work-items/management/override-request'
};

const workItemsReferred: DciRoute = {
    pattern: '/workitems/referred',
    buildLink: () => '/workitems/referred',
    helpLink: '/work-items/management/referred'
};

const workItemsCorrected: DciRoute = {
    pattern: '/workitems/corrected',
    buildLink: () => '/workitems/corrected',
    helpLink: '/work-items/completed/corrected'
};

const workItemsOverride: DciRoute = {
    pattern: '/workitems/override',
    buildLink: () => '/workitems/override',
    helpLink: '/work-items/completed/override'
};

const core: DciRoute = {
    pattern: '/core',
    buildLink: () => '/core'
};

const coreTables: DciRoute = {
    pattern: '/configuration/core/tables',
    buildLink: () => '/configuration/core/tables'
};

const coreTag: DciRoute = {
    pattern: '/configuration/core/tag/:id',
    buildLink: (tagId : string | number) => `/configuration/core/tag/${tagId}`
};

const coreTags: DciRoute = {
    pattern: '/configuration/core/tags',
    buildLink: () => '/configuration/core/tags',
    regex: /^\/configuration\/core\/tag(s|\/\d+)$/
};

const coreRole: DciRoute = {
    pattern: '/configuration/core/role/:id',
    buildLink: (coreRoleId: number) => `/configuration/core/role/${coreRoleId}`
}

const coreRoles: DciRoute = {
    pattern: '/configuration/core/roles',
    buildLink: () => '/configuration/core/roles'
}

const coreRulesList: DciRoute = {
    pattern: '/configuration/core/rules',
    buildLink: () => '/configuration/core/rules'
};

const coreTable: DciRoute = {
    pattern: '/core/table/:id',
    buildLink: (extractTableId: string | number) => `/core/table/${extractTableId}`,
};

const coreSchema: DciRoute = {
    pattern: '/core/schema',
    buildLink: () => '/core/schema'
};

const root: DciRoute = {
    pattern: '/',
    buildLink: () => '/'
}

const dciPaths = {
    audit,
    configuration,
    core,
    coreRole,
    coreRoles,
    coreRulesList,
    coreTag,
    coreTags,
    coreTables,
    coreTable,
    coreSchema,
    dashboard,
    history,
    masterRules,
    organisation,
    orgConstants,
    orgValueLists,
    reporting,
    reportingRun,
    role,
    roles,
    root,
    rule,
    rules,
    ruleGroup,
    ruleGroups,
    settings,
    tag,
    tags,
    team,
    teams,
    user,
    userActivity,
    users,
    workItem,
    workItemAttributeMap,
    workItemAttributeMaps,
    workItemsDistribution,
    workItemsQueued,
    workItemsInProgress,
    workItemsAwaitingInformation,
    workItemsOverrideRequest,
    workItemsReferred,
    workItemsCorrected,
    workItemsOverride,
    workQueue,
    workQueues,
}

export default dciPaths;
export type { DciRoute }