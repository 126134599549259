import { useAuth0 } from "@auth0/auth0-react";
import { 
    Typography
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getDciApiResponse } from "../../utils/callDciApi";
import { FilterControlProps } from "./types";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { UsersClient } from "../Api/UserApi";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";

interface ApiResponseUser {
    userId: number,
    firstName: string,
    surname: string
}

interface User {
    userId: number,
    displayText: string
}

const ME_USER_ID = 0;

const MeValue = {
    userId: ME_USER_ID,
    displayText: 'Me'
};

const fetchUsers = async (token: string): Promise<User[]> => {
    const response = await getDciApiResponse('{allUsers{nodes{userId,firstName,surname}}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        const apiResponseUsers = response.data.allUsers.nodes as ApiResponseUser[];
        return [
            MeValue,
            ...apiResponseUsers.map(au => ({ userId: au.userId, displayText: [au.firstName, au.surname].join(' ')}))
        ]
        
    }
    // TODO: Error handling
}

const useUsers = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ["users"],
        queryFn: async () => fetchUsers(await getAccessTokenSilently())
    })
}

const UserFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const { data, isFetching } = useUsers();

    const selectedUsers = value as User[];

    const getTextValue = (value: User[]) => value. length === 1 
        ? selectedUsers[0].displayText
        : `${selectedUsers.length} selected`

    const setAutoCompleteValue = (newValue: User[]) => {
        setValue(newValue, newValue.length > 0, false);
    }

    if (editMode) {
        return <FilterAutocomplete
            data={data}
            getOptionLabel={o => o.displayText}
            getTextValue={getTextValue}
            isLoading={isFetching}
            isOptionEqualToValue={(o, v) => o.userId === v.userId}
            selectedOptions={selectedUsers}
            setValue={setAutoCompleteValue}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {selectedUsers.length === 1 
                ? ['=', selectedUsers[0].displayText].join(' ')
                : `= ${selectedUsers.length} selected`}
        </Typography>
    )
}

const UserFilterDefaultValue = [] as User[];

const serializer: FilterUrlSerializer = {
    serialize: (f: User[]) => f.map(u => u.userId).join(),
    deserialize: async s => {
        const users = await UsersClient.get();

        return s
            .split(',')
            .map(v => parseInt(v))
            .filter(v => !isNaN(v))
            .map(v => users.find(u => u.userId === v))
            .filter(v => v !== undefined)
            .map(v => ({
                userId: v!.userId,
                displayText: [ v!.firstName, v!.surname ].join(' ')
            }) as User)
    }
}

registerFilterUrlSerializer(FILTER_TYPE.USER, serializer);

const converter: FilterToParameterConverter = (name: string, value: User[]) => ({
    name: name,
    type: PARAMETER_TYPE.NUMBER,
    value: value.map(u => u.userId)
});

registerParameterConverter(FILTER_TYPE.USER, converter);

export { MeValue, UserFilter, UserFilterDefaultValue }