import { useEffect, useState } from 'react';
import {
    InputBase,
    Typography
} from '@mui/material';
import { FilterControlProps } from './types';
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from './FilterRepository';
import { PARAMETER_TYPE } from '../../GraphQLShared';

const TextFilter = ({ editMode, setEditMode, value, setValue }: FilterControlProps) => {
    const [ editValue, setEditValue ] = useState<string>(value)

    const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setEditValue(event.target.value);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setValue(event.target.value, true, false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            setValue(event.target.value, true, true);
            setEditMode(false);
        }
    }

    useEffect(() => setValue(value, typeof value === 'string', false), [])
    useEffect(() => { 
        if (editMode) {
            setEditValue(value);
        } 
    }, [ value ])

    return editMode ?
        <InputBase
            sx={theme => ({ 
                border:`1px solid ${theme.palette.text.primary}`,
                minWidth:'200px',
                marginRight:'5px',
                '& > input': {
                    padding:'2px 6px',
                    fontSize:'14px'
                }
            })}
            value={editValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder='Filter Text'
        /> :
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>{`= ${!value || value === '' ? '<empty>' : value}`}</Typography>
}

const TextFilterDefaultValue = '';

const converter: FilterToParameterConverter = (name: string, value: string) => ({
    name: name,
    type: PARAMETER_TYPE.TEXT,
    value: value
});

registerParameterConverter(FILTER_TYPE.FREE_TEXT, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: string) => f,
    deserialize: async s => s
}

registerFilterUrlSerializer(FILTER_TYPE.FREE_TEXT, serializer);

export {
    TextFilter,
    TextFilterDefaultValue
}