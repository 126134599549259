import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableState, TableStoreContext, createTableStore } from './Table/Stores/TableStore';
import { TableContainer } from './Table/TableContainer';
import { Table } from './Table/Table';
import { TableColumn } from './Filtering/types';

type Tag = {
    tagId: number,
    name: string,
    description: string | null,
    colour: string | null,
    RuleCount: number
}

const createStore = <TRow,>(graphQLQueryName: string,
    graphQLQueryColumns: string,
    uniqueSortColumn: string,
    idFromRow: (row: TRow) => number,
    initialState?: Partial<TableState>,
    tableStoreName?: string
) => createTableStore({
        graphQLQueryName: graphQLQueryName,
        graphQLQueryColumns: graphQLQueryColumns,
        uniqueSortColumn: uniqueSortColumn,
        idFromRow: idFromRow
    }, initialState, tableStoreName);

interface StandardListViewProps<TRow> {
    title: string
    columns: TableColumn[]
    toolbarButtons?: JSX.Element
    buildLink?: (row: TRow) => string
    graphQLQueryName: string
    graphQLQueryColumns: string
    uniqueSortColumn: string
    idFromRow: (row: TRow) => number
    initialState?: Partial<TableState>
    tableStoreName?: string
}

const StandardListView = <TRow,>({
    title,
    columns,
    toolbarButtons,
    buildLink,
    graphQLQueryName,
    graphQLQueryColumns,
    uniqueSortColumn,
    idFromRow,
    initialState,
    tableStoreName
}: StandardListViewProps<TRow>) => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore(graphQLQueryName, graphQLQueryColumns, uniqueSortColumn, idFromRow, initialState, tableStoreName))

    return (
        <TableStoreContext.Provider value={store}>
            <TableContainer
                title={title}
                paged
                style={{ height:'100%', width:'100%' }}
                enableExport
                toolbarButtons={toolbarButtons}
            >
                <Table
                    uniqueSortColumn='tagId'
                    columns={columns}
                    idFromValue={idFromRow}
                    onRowClick={ buildLink === undefined ? undefined : (row: TRow) => navigate(buildLink(row))}
                    selectable
                />
            </TableContainer>
        </TableStoreContext.Provider>
    )
};

export { StandardListView }