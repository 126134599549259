import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/NewTag.png';

const WNNewTag = () => {
    return (
        <>
            <Typography variant='h5'>Tags</Typography>
            <Typography variant='body1'>Tags have a name, an optional description and a colour.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="New Tag" />
        </>
    )
}

export { WNNewTag }