import { useState } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import callDciApi from '../utils/callDciApi';
import { useSnackbar } from 'notistack';
import { ColourPicker, getRandomHexColour } from './ColourPicker';
import { FieldCaption } from './DciControls';
import { Tag } from './Api/ReactQuery';

type CreateTagDialogProps = {
    isOpen: boolean,
    close: (tagCreated?: Tag) => void
};

const CreateTagDialog = ({ isOpen, close }: CreateTagDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ tagName, setTagName ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ colour, setColour ] = useState(getRandomHexColour());
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const onSave = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{createTag(tagName:"${tagName}",colour:"${colour}"${description === '' ? '' : `,description:"""${description}"""`}){tagId name description colour}}`, token)
        .then(body => {
            if (!body.errors) {
                close({
                    colour: body.data.createTag.colour,
                    description: body.data.createTag.description,
                    isCoreTag: false,
                    name: body.data.createTag.name,
                    tagId: body.data.createTag.tagId
                });
                enqueueSnackbar(`Tag "${tagName}" created`, { variant:'success' });
                setTagName('');
            } else {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            }
        })
        .catch(error => {
            console.error(`[AddTeamDialog] createTeam: ${error}`);
            enqueueSnackbar('An error occurred while creating the tag', { variant:'error' });
        });
    };

    const prepareClose = () => {
        setTagName('');
        setDescription('');
        setColour(getRandomHexColour()); // Workaround for not setting when opened if not unmounted from parent
    }

    return (
        <Dialog
            disableRestoreFocus
            onClose={() =>{
                prepareClose();
                close();
            }}
            open={isOpen}
            fullScreen={fullScreen}
            maxWidth={'sm'}
            fullWidth
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">New Tag</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Type in a name for the tag"
                    type="text"
                    fullWidth
                    variant='standard'
                    value={tagName}
                    onChange={(event) => setTagName(event.target.value)}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Optionally, enter a description"
                    type="text"
                    fullWidth
                    variant='standard'
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                />
                <Stack sx={{ paddingTop:'10px' }} direction='row' alignItems='center'>
                    <Typography>Colour:</Typography>
                    <ColourPicker colour={colour} setColour={setColour} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { prepareClose(); close(); }}>Cancel</Button>
                <Button disabled={tagName === ''} onClick={onSave}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export { CreateTagDialog }