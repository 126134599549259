import { useState } from 'react';
import { AddBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import { 
    Button,
    TableCell, 
    alpha
} from '@mui/material';

import OrgContainer from './OrgContainer';
import { AddRoleDialog } from './AddRoleDialog';
import dciPaths from '../../utils/dciPaths';
import { TableStoreContext, createTableStore, useTableState } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';

type Role = {
    roleId: number,
    description: string,
    enabled: boolean
}

const columns: TableColumn[] = [
    {
        displayName: "Description",
        selector: "description"
    },
    {
        displayName: "Enabled",
        selector: "enabled",
        render: row => (<TableCell key={'enabled'} style={{ textAlign: 'center' }} padding={'checkbox'}>{row.enabled ? <Check /> : <Clear />}</TableCell>)
    }
];

const createStore = () => createTableStore({
        graphQLQueryName: 'allRoles',
        graphQLQueryColumns: '{roleId,description,enabled}',
        uniqueSortColumn: 'roleId',
        idFromRow: row => row.reportingRunHistoryId
    },
    {
        paged: true
    });

const Controls = () => {
    const [ addRoleDialogIsOpen, setAddRoleDialogIsOpen ] = useState(false);
    const updateData = useTableState(s => s.updateRowData);

    const closeDialog = (refresh: boolean) => {
        setAddRoleDialogIsOpen(false);
        if (refresh) {
            updateData(false);
        }
    }
    
    return <>
        <Button
            sx={theme => ({ 
                color:theme.palette.text.primary,
                borderColor:theme.palette.divider,
                '&:hover': {
                    borderColor: theme.palette.action.disabledBackground,
                    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                }
            })}
            style={{ margin:'0px 4px' }}
            endIcon={<AddBox />}
            variant='outlined'
            onClick={() => setAddRoleDialogIsOpen(true)}
            aria-label='new role'>
                New
        </Button>
        <AddRoleDialog isOpen={addRoleDialogIsOpen} close={closeDialog} />
    </>;
}

export default function Roles() {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore())

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Roles'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                    toolbarButtons={<Controls />}
                >
                    <Table
                        uniqueSortColumn='roleId'
                        columns={columns}
                        idFromValue={row => row.roleId}
                        onRowClick={row => navigate(dciPaths.role.buildLink(row.roleId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    )
};